<i18n lang="yaml">
pt:
  show: 'Mostrar {label}'
  hide: 'Ocultar {label}'
en:
  show: 'Show {label}'
  hide: 'Hide {label}'
</i18n>
<template>
  <div
    class="deck-accordion"
    :class="classes"
  >
    <!-- TODO: when removing `is-ready` also check below for considering removing toggle button color overwrite  -->
    <!--
      triggered when the mouse is over the toggle button
      @event mouseover:toggle-button
    -->
    <!--
      triggered when the mouse leaves the toggle button
      @event mouseleave:toggle-button
    -->
    <deck-button
      v-if="!contentOnly"
      class="deck-accordion__toggle-button"
      is-ready
      block
      kind="ghost"
      color="controls"
      :icon-append="!indented ? expandIcon : null"
      :icon-prepend="indented ? expandIcon : null"
      :disabled="disabled"
      :aria-label="showContent ? t('hide', { label }) : t('show', { label })"
      :aria-expanded="String(Boolean(showContent))"
      :aria-controls="computedId"
      @click="toggleAccordion"
      @mouseleave="() => $emit('mouseleave:toggleButton')"
      @mouseover="() => $emit('mouseover:toggleButton')"
    >
      <!--
        @slot For rendering custom content before the header label
        Attention: Using this overwrites the prepended icon rendering.
      -->
      <slot name="prepend-header-label">
        <deck-icon
          v-if="labelIconPrepend"
          :name="labelIconPrepend"
          :color="labelIconPrependColor"
          class="mr-2"
        />
      </slot>

      <span class="mr-auto flex-1">
        <!--
          @slot For rendering custom content on the header toggle button
          Attention: Using this overwrites the label rendering (but not in it's aria label).
        -->
        <slot name="header-label">
          <deck-trimmer
            v-bind="computedTrimmerProps"
            tag="span"
            :text="label"
          />
        </slot>
      </span>

      <!--
        @slot For rendering custom content after the header label
        Attention: Using this overwrites the appended icon rendering.
      -->
      <slot name="append-header-label">
        <deck-icon
          v-if="labelIconAppend"
          :name="labelIconAppend"
          :color="labelIconAppendColor"
          class="ml-2"
        />
      </slot>
    </deck-button>

    <deck-expander
      :id="computedId"
      :model-value="showContent"
      content-class="deck-accordion__content"
    >
      <!-- @slot Default content slot -->
      <slot />
    </deck-expander>
  </div>
</template>
<script>
import DeckButton from '~/deck/button';
import DeckIcon from '~/deck/icon';
import DeckExpander from '~/deck/expander';
import DeckTrimmer from '~/deck/trimmer';

export default {
  name: 'DeckAccordion',
  components: {
    DeckButton,
    DeckIcon,
    DeckExpander,
    DeckTrimmer,
  },
  props: {
    /**
     * Controls the accordion open state from outside.
     * Open by default since it's the most common use case.
     */
    modelValue: {
      type: Boolean,
      default: true,
    },

    /**
     * The label for the accordion header and used for aria-label.
     * Note: Required for accessibility even if you use a custom header-label slot.
     */
    label: {
      type: String,
      required: true,
    },

    /**
     * The icon to be displayed before the label.
     */
    labelIconPrepend: {
      type: String,
      default: null,
    },

    /**
     * The color of the icon displayed before the label.
     */
    labelIconPrependColor: {
      type: String,
      default: null,
    },

    /**
     * The icon to be displayed after the label.
     */
    labelIconAppend: {
      type: String,
      default: null,
    },

    /**
     * The color of the icon displayed after the label.
     */
    labelIconAppendColor: {
      type: String,
      default: null,
    },

    /**
     * Hides the borders of the accordion. Useful when you want to use the
     * accordion inside a card while occupying the full width.
     */
    hideBorders: {
      type: Boolean,
      default: false,
    },

    /**
     * Disables toggling the accordion and render it in a disabled state.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the accordion content should occupy the full width of its container.
     */
    fullWidthContent: {
      type: Boolean,
      default: false,
    },

    /**
     * Truncation props to be passed to the underlying `deck-trimmer` component of the accordion header-label
     */
    trimmerProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Whether the accordion should render in a indented variant style.
     * Will hide borders, and render full-width automatically
     */
    indented: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the accordion should render only the content without the toggle button.
     */
    contentOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['mouseleave:toggleButton', 'mouseover:toggleButton', 'update:modelValue'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      isOpen: this.modelValue,
    };
  },
  computed: {
    showContent() {
      return this.isOpen;
    },
    classes() {
      return {
        'deck-accordion--hide-borders': this.hideBorders || this.indented,
        'deck-accordion--disabled': this.disabled,
        'deck-accordion--full-width-content': this.fullWidthContent || this.indented,
        'deck-accordion--indented': this.indented,
        'deck-accordion--content-only': this.contentOnly,
      };
    },

    computedId() {
      return `deck-accordion-${this.$.uid}`;
    },

    expandIcon() {
      if (this.disabled) return null;

      return this.showContent ? 'chevron-down' : 'chevron-right';
    },
    computedTrimmerProps() {
      return {
        lineClamp: 2,
        ...this.trimmerProps,
      };
    },
  },
  watch: {
    modelValue(newValue) {
      this.isOpen = newValue;
    },
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;

      /**
       * Reflects the accordion open state. Bound to v-model.
       * @event update:modelValue
       */
      this.$emit('update:modelValue', this.isOpen);
    },
  },
};
</script>

<style lang="scss">
.deck-accordion {
  --deck-accordion-padding-inline: var(--z-s2);
  --deck-accordion-padding-block: var(--z-s2);

  box-shadow: 0 0 0 1px var(--z-input-border-color); // Border with no layout impact
  padding: var(--deck-accordion-padding-block) var(--deck-accordion-padding-inline);
  border-radius: var(--z-border-radius-base);
}

.deck-accordion__toggle-button {
  // Eventually think of a way to not modify inner deck components
  --deck-button-padding-inline: calc(var(--deck-accordion-padding-inline)) !important;

  text-align: left;
  display: flex;
}

.deck-accordion__content {
  margin-top: var(--deck-accordion-padding-block);
  padding-bottom: var(--deck-accordion-padding-block);
  padding-inline: var(--deck-accordion-padding-inline);
}

.deck-accordion--hide-borders {
  box-shadow: none;
  border-radius: 0px;
}

.deck-accordion--disabled {
  .deck-accordion__toggle-button {
    justify-content: flex-start; // To align the label to the left when there is no right chevron icon on the button
  }
}

.deck-accordion--full-width-content {
  --deck-accordion-padding-inline: 0;

  .deck-accordion__toggle-button {
    --deck-button-padding-inline: var(--z-s2) !important;
    margin-inline: var(--z-s2);
  }
}

.deck-accordion--indented {
  padding-block: 0;

  .deck-accordion__toggle-button {
    margin-inline: 0;
  }

  .deck-accordion__content {
    padding-left: 30px;
  }
}

.deck-accordion--content-only {
  padding: 0;

  .deck-expander__content {
    padding: 0;
    margin: 0;
  }
}
</style>
