<i18n lang="yaml">
pt:
  title: 'Insira o código de verificação'
  subtitle: 'Enviamos um código de 6 dígitos para o seu e-mail'
en:
  title: 'Insert the verification code'
  subtitle: 'We sent a 6-digit code to your email'
</i18n>
<template>
  <AuthLogin
    :layout-attrs="layoutAttrs"
    :modules="[
      'emailValidation'
    ]"
  />
</template>

<script lang="ts">
import AuthLogin from '~/components/auth/login/index.vue';

export default defineComponent({
  name: 'EmailValidation',
  components: { AuthLogin },
  setup() {
    const { t } = useI18n();

    definePageMeta({
      middleware: ['auth-redirect'],
    });

    return { t };
  },
  computed: {
    layoutAttrs() {
      return {
        title: this.t('title'),
        subtitle: this.t('subtitle'),
        dark: true,
        hideSignUpDisclaimer: true,
      };
    },
  },
});
</script>
