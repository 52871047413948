// https://www.fusejs.io/
import Fuse from 'fuse.js';
import { slugify } from '~/assets/javascript/utils/string';
import { isEmpty } from 'lodash';

const facetMatches = (facetValue, expectedFacetValue) => {
  if (Array.isArray(facetValue)) {
    return expectedFacetValue.some(expectedValue => facetValue.includes(expectedValue));
  }

  return expectedFacetValue.includes(facetValue);
};

const itemFacetsMatch = (item, expectedFacets) => Object
  .entries(expectedFacets)
  .every(([key, expectedValue]) => facetMatches(item[key], expectedValue));

const countFacets = ({
  facets,
  facetKeys,
  facetsOptions,
  searchTermItems,
}) => {
  const result = Object.entries(facets).reduce((acc, [facet, selectedFacets]) => {
    acc[facet] = selectedFacets.reduce((acc2, selectedFacet) => {
      acc2[selectedFacet] = 0;
      return acc2;
    }, {});

    return acc;
  }, {});

  searchTermItems.forEach((item) => {
    Object.entries(facetsOptions).forEach(([key, value]) => {
      value.forEach((internalValue) => {
        const facetsTest = { ...facets, [key]: [internalValue] };
        const matches = itemFacetsMatch(item, facetsTest);

        if (!matches) return;

        result[key] ||= {};
        result[key][internalValue] ||= 0;
        result[key][internalValue] += 1;
      });
    });
  });

  return Object
    .entries(result)
    .map(([key, value]) => ({
      key,
      value: Object.entries(value).map(([facet, count]) => ({ facet, count })).sort((a, b) => a.facet.localeCompare(b.facet)),
    }))
    .sort((a, b) => facetKeys.indexOf(a.key) - facetKeys.indexOf(b.key));
};

export class LocalSearchStrategy {
  constructor({
    facetKeys = [],
    items,
    searchableKeys = ['name', 'description'],
    useFuzzySearch = true,
  }) {
    this.cachedFacetsOptions = {};

    this.facetsOptions = () => {
      if (_isEmpty(this.cachedFacetsOptions)) {
        this.cachedFacetsOptions = facetKeys.reduce((acc, key) => {
          acc[key] = [...new Set(items.map(item => item[key]).flat())];
          return acc;
        }, {});
      }

      return this.cachedFacetsOptions;
    };

    this.items = items;
    this.facetKeys = facetKeys;
    this.searchableKeys = searchableKeys;

    if (useFuzzySearch) {
      const fuse = new Fuse(items, {
        keys: searchableKeys,
        shouldSort: true,
        ignoreLocation: true,
        includeScore: true,
      });

      this.searchMethod = searchTerm => fuse.search(searchTerm).map(({ item }) => item);
    } else {
      const searchableValuesCache = {};

      this.searchMethod = (searchTerm) => {
        const term = slugify(searchTerm);

        return items.filter((item, index) => {
          const searchableValues = searchableValuesCache[index] || searchableKeys.map(key => slugify(item[key]));
          if (!searchableValuesCache[index]) searchableValuesCache[index] = searchableValues;

          return searchableValues.some(value => value.includes(term));
        });
      };
    }
  }

  search(searchTerm, facets = {}) {
    const searchTermItems = isEmpty(searchTerm) ? this.items : this.searchMethod(searchTerm);

    const result = searchTermItems.filter(item => itemFacetsMatch(item, facets));

    const facetsCount = countFacets({
      facets,
      items: result,
      facetKeys: this.facetKeys,
      facetsOptions: this.facetsOptions(),
      searchTermItems,
    });

    return {
      result,
      facets: facetsCount,
      count: result.length,
    };
  }
}
