import type { NuxtApp } from '#app';
import type { Router } from 'vue-router';
import LoginCallback from '../callback';

function decodeUsername(username) {
  if (!username) return username;

  return decodeURIComponent(username);
}

export class Module {
  $apiClient: NuxtApp['$apiClient'];

  $auth: NuxtApp['$auth'];

  $recaptcha: NuxtApp['$recaptcha'];

  $router: Router;

  callbackHandler: LoginCallback;

  constructor(nuxt: NuxtApp) {
    this.$apiClient = nuxt.$apiClient;
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.$router = nuxt.$router as Router;
    this.callbackHandler = new LoginCallback(nuxt);
  }

  get route() {
    return this.$router.currentRoute.value;
  }

  get usernameFromRoute() {
    return decodeUsername(this.route.query.username);
  }

  async validateAndLogin(otp, { redirectTo = null } = {}) {
    await this.$auth.loginWith('local', {
      url: `authentication/otp/${this.route.params.tenantSlug}`,
      method: 'post',
      body: {
        token: otp,
        username: this.usernameFromRoute,
      },
    });

    return this.callbackHandler.handle({ withQuery: true, redirectTo });
  }

  async sendEmail(username?: string, tenantSlug?: string): Promise<void>
  async sendEmail(username = undefined, tenantSlug = undefined) {
    return this.$recaptcha.fetchToken(async (token) => {
      const params = {
        username: decodeUsername(username) || this.usernameFromRoute,
        recaptcha_token: token,
      };

      return this.$apiClient.publicRoutes.workspace.emailValidations.create(
        tenantSlug || this.route.params.tenantSlug as string,
        params,
      );
    });
  }
}

export function SetupEmailValidation(nuxt) {
  nuxt.$auth.emailValidationModule = new Module(nuxt);
}
