<i18n lang="yaml">
  pt:
    title: 'Olá! Bom te ver'
    favorite: 'Páginas favoritas'
    recent: 'Páginas recentes'
    pinned: 'Em destaque'
    admin: 'Admin'
    installedApps: 'Biblioteca de apps'
    favoriteApps:
      title: 'Apps favoritos'
      add: 'Adicionar app aos favoritos'
      remove: 'Remover app dos favoritos'
    other: 'Outros acessos'
    store: 'Loja de apps'
    storeSubtitle: 'A solução mais rápida para seus problemas'
    studio: 'Estúdio no-code'
    studioSubtitle: 'Crie e customize suas próprias soluções'
    experts: 'Experts'
    expertsSubtitle: 'Quer ajuda especializada para criar soluções únicas?'
    public: 'Área pública'
    publicSubtitle: 'Acesse as páginas que ficam disponíveis na internet'
    uninstall: 'Desinstalar app'
    uninstallAppWarningMessage: 'Esta aplicação será desinstalada e todas as páginas e automações criados por ela serão removidos. Os seus dados não serão afetados. As seguintes dependências serão removidas:'
  en:
    title: 'Hello! Good to see you'
    favorite: 'Favorite pages'
    recent: 'Recent pages'
    pinned: 'Featured'
    admin: 'Admin'
    installedApps: 'App library'
    favoriteApps:
      title: 'Favorite apps'
      add: 'Add app to favorites'
      remove: 'Remove app from favorites'
    other: 'Other Areas'
    store: 'App store'
    storeSubtitle: 'The fastest way to solve your problems'
    studio: 'No-code Studio'
    studioSubtitle: 'Create and customize your own solutions'
    experts: 'Experts'
    expertsSubtitle: 'Need help with unique solutions?'
    public: 'Public area'
    publicSubtitle: 'Access pages that are available on the internet'
    uninstall: 'Uninstall app'
    uninstallAppWarningMessage: 'This app will be uninstalled and all pages and automations created by it will be removed. Your data will not be affected. The following dependencies will be removed:'
</i18n>

<template>
  <GridSystem
    class="home-member"
    vertical
  >
    <GridSystemItem>
      <GridSystem :items-per-row="3">
        <GridSystemItem v-if="pinnedItems.length > 0">
          <Ranking
            :title="t('pinned')"
            icon="fa-thumbtack fa-regular"
            :items="pinnedItems"
          />
        </GridSystemItem>

        <GridSystemItem v-if="favoriteItems.length > 0">
          <Ranking
            :title="t('favorite')"
            icon="fa-star fa-regular"
            :items="favoriteItems"
          />
        </GridSystemItem>

        <GridSystemItem v-if="recentItems.length > 0">
          <Ranking
            :title="t('recent')"
            icon="fa-clock-rotate-left fa-regular"
            :items="recentItems"
          />
        </GridSystemItem>
      </GridSystem>
    </GridSystemItem>

    <GridSystemItem v-if="favoriteAppItems.length > 0">
      <h2 class="pb-8 pt-4">{{ t('favoriteApps.title') }}</h2>

      <GridSystem
        :items="favoriteAppItems"
        :items-per-row="3"
      >
        <template #item="{ item }">
          <div class="home-member__app-card-wrapper">
            <div
              v-if="loading[item.name]"
              class="home-member__app-card-loader"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>

            <CardApp
              :title="item.title"
              :subtitle="item.subtitle"
              :icon="item.icon"
              :to="item.to"
              :href="item.href"
              :target="item.href ? '_blank' : null"
              :image-url="item.imageUrl"
            />

            <v-menu bottom>
              <template #activator="{ props }">
                <deck-button
                  v-bind="props"
                  icon="fa-ellipsis-v"
                  is-ready
                  color="controls"
                  size="small"
                  class="home-member__app-card-menu"
                  kind="ghost"
                  :text="$t('global.options')"
                />
              </template>

              <v-list density="compact">
                <v-list-item
                  density="compact"
                  size="small"
                  @click="removeFavoriteAppId(item.id)"
                >
                  <v-list-item-title>
                    {{ t('favoriteApps.remove') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="isBuilder"
                  density="compact"
                  size="small"
                  :disabled="loading[item.name]"
                  @click="openDialogUninstallApp(item)"
                >
                  <v-list-item-title>
                    {{ t('uninstall') }}

                    <v-progress-circular
                      v-if="loading[item.name]"
                      indeterminate
                    />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </GridSystem>
    </GridSystemItem>

    <GridSystemItem v-if="normalAppItems.length > 0">
      <h2 class="pb-8 pt-4">{{ t('installedApps') }}</h2>

      <GridSystem
        :items="normalAppItems"
        :items-per-row="3"
      >
        <template #item="{ item }">
          <div class="home-member__app-card-wrapper">
            <div
              v-if="loading[item.name]"
              class="home-member__app-card-loader"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>

            <CardApp
              :title="item.title"
              :subtitle="item.subtitle"
              :icon="item.icon"
              :to="item.to"
              :href="item.href"
              :target="item.href ? '_blank' : null"
              :image-url="item.imageUrl"
            />

            <v-menu bottom>
              <template #activator="{ props }">
                <deck-button
                  v-bind="props"
                  icon="fa-ellipsis-v"
                  is-ready
                  color="controls"
                  size="small"
                  class="home-member__app-card-menu"
                  kind="ghost"
                  :text="$t('global.options')"
                />
              </template>

              <v-list density="compact">
                <v-list-item
                  density="compact"
                  size="small"
                  @click="addFavoriteAppId(item.id)"
                >
                  <v-list-item-title>
                    {{ t('favoriteApps.add') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="isBuilder"
                  density="compact"
                  size="small"
                  :disabled="loading[item.name]"
                  @click="openDialogUninstallApp(item)"
                >
                  <v-list-item-title>
                    {{ t('uninstall') }}

                    <v-progress-circular
                      v-if="loading[item.name]"
                      indeterminate
                    />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </GridSystem>
    </GridSystemItem>

    <GridSystemItem v-if="otherItems.length > 0">
      <h2 class="pb-4 pt-8">{{ t('other') }}</h2>
      <GridSystem
        :items="otherItems"
        :items-per-row="4"
        :max-items="8"
      >
        <template #item="{ item }">
          <CardHome
            :title="item.title"
            :subtitle="item.subtitle"
            :icon="item.icon"
            :to="item.to"
            :href="item.href"
            :image-url="item.imageUrl"
            :gradient="item.gradient"
          />
        </template>
      </GridSystem>
    </GridSystemItem>
  </GridSystem>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapState, mapMutations } from '~/assets/javascript/modules/vuex';
import ROUTES from '~/assets/javascript/constants/routes';
import {
  getRecentNavigation,
  getFirstViewIdFromCategory,
} from '~/assets/javascript/utils';
import CardHome from '~/components/cards/home';
import CardApp from '~/components/cards/app';
import GridSystem from '~/components/grid-system';
import GridSystemItem from '~/components/grid-system/item';
import Ranking from '~/components/collections/ranking';
import { dataMixin } from '~/mixins/dataMixin';

const getRecentViewIds = () => getRecentNavigation('member', 'views').map(route => route.params.viewId);

export default {
  name: 'HomeMember',
  components: {
    GridSystem,
    GridSystemItem,
    CardHome,
    CardApp,
    Ranking,
  },
  mixins: [dataMixin],
  setup() {
    definePageMeta({
      layout: 'home',
      middleware: [
        'auth',
        async () => {
          const $auth = useAuth();
          const { $localePath, $store } = useNuxtApp();

          if ($auth.user?.role !== 'admin') {
            await $store.dispatch('workspace/loadWorkspace', { tenantSlug: $auth.user?.workspace.slug, skipNextCall: true });
            const categoryHierarchy = $store.getters['workspace/categoryHierarchy'];

            if (categoryHierarchy.length === 1) {
              return navigateTo($localePath({
                name: 't-tenantSlug-m-viewId',
                params: {
                  tenantSlug: $auth.user?.workspace.slug,
                  viewId: getFirstViewIdFromCategory(categoryHierarchy[0]),
                },
              }));
            }
          }

          return undefined;
        },
      ],
    });

    return {
      ...mapGetters('workspace', ['categoryHierarchy', 'allSystemHiddenViewIds', 'allPublicViewIds']),
      ...mapGetters('apps', ['getAppByName']),
      ...mapActions('viewsMenu', ['loadViews']),
      ...mapState('viewsMenu', ['views']),
      ...mapState('userConfigs', ['favoriteViewIds', 'favoriteAppIds']),
      ...mapMutations('userConfigs', ['addFavoriteAppId', 'removeFavoriteAppId']),
      ...mapActions('apps', ['loadApps', 'uninstallApp']),
      ...mapActions('workspace', ['loadWorkspace']),
      ...mapMutations('dialog', ['openDialog']),
      ...mapState('workspace', ['workspace']),
      t: useI18n().t,
    };
  },
  data() {
    return {
      loading: {},
      recentViewIds: [],
    };
  },
  computed: {
    isBuilder() {
      return this.$auth.user.role === 'admin';
    },
    viewsById() {
      return this.views.reduce((acc, view) => {
        acc[view.id] = view;
        return acc;
      }, {});
    },
    pinnedViewIds() {
      return this.views.filter(view => view.page_pinned).map(view => view.id);
    },
    pinnedItems() {
      return this.buildViewItems(this.pinnedViewIds);
    },
    favoriteItems() {
      return this.buildViewItems(this.favoriteViewIds);
    },
    recentVisibleViewIds() {
      return this.recentViewIds.filter(viewId => !this.allSystemHiddenViewIds.includes(viewId)).slice(0, 5);
    },
    recentItems() {
      return this.buildViewItems(this.recentVisibleViewIds);
    },
    otherItems() {
      const items = [] as Array<{
        title: string,
        subtitle: string,
        gradient: number,
        icon: string,
        to?: string,
        href?: string,
        imageUrl?: string,
      }>;

      if (this.allPublicViewIds.length > 0) {
        items.push({
          title: this.t('public'),
          subtitle: this.t('publicSubtitle'),
          gradient: 1,
          icon: 'globe',
          to: this.localePath({
            name: ROUTES.public.views.routeName,
            params: {
              tenantSlug: this.$route.params.tenantSlug,
            },
          }),
        });
      }

      if (['custom', 'genius'].includes(this.workspace.plan)) {
        items.push({
          title: this.t('studio'),
          subtitle: this.t('studioSubtitle'),
          gradient: 2,
          icon: 'pen-ruler',
          to: this.localePath({
            name: ROUTES.studio.views.routeName,
            params: {
              tenantSlug: this.$route.params.tenantSlug,
            },
          }),
        });

        items.push({
          title: this.t('experts'),
          subtitle: this.t('expertsSubtitle'),
          gradient: 4,
          icon: 'gem',
          href: 'https://calendly.com/nai-leal/zazos',
        });
      }

      if (this.isBuilder) {
        items.push({
          title: this.t('store'),
          subtitle: this.t('storeSubtitle'),
          gradient: 3,
          icon: 'store',
          href: 'https://www.zazos.com/',
        });
      }

      return items;
    },
    normalAppItems() {
      return this.appItems.filter(app => !this.favoriteAppIds.includes(app.id));
    },
    favoriteAppItems() {
      return this.appItems.filter(app => this.favoriteAppIds.includes(app.id));
    },
    appItems() {
      return this
        .categoryHierarchy
        .filter(category => category.views?.length > 0)
        .map(category => ({
          id: category.id,
          name: category.category_system_name,
          title: category.category_name,
          subtitle: category.category_description,
          icon: category.category_icon,
          imageUrl: this.appImageUrl(category),
          views: category.views,
          workflows: category.workflows,
          to: this.localePath({
            name: ROUTES.member.view.routeName,
            params: {
              tenantSlug: this.$route.params.tenantSlug,
              viewId: getFirstViewIdFromCategory(category),
            },
          }),
        }));
    },
  },
  async beforeMount() {
    await this.fetchData();
    this.recentViewIds = getRecentViewIds();
  },
  methods: {
    fetchData() {
      return this.loadViews({ tenantSlug: this.$route.params.tenantSlug });
    },
    appImageUrl(app) {
      if (app.category_image_url) return app.category_image_url;
      if (!app.category_system_name) return undefined;

      return `/apps/card/${app.category_system_name}.png`;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    buildViewItems(viewIds) {
      return viewIds.map((viewId) => {
        const view = this.viewsById[viewId];

        if (!view || view.archived_at) return undefined;

        return {
          title: view.page_name,
          subtitle: view.page_description,
          icon: view.category?.category_icon,
          to: this.localePath({
            name: ROUTES.member.view.routeName,
            params: {
              tenantSlug: this.$route.params.tenantSlug,
              viewId: view.id,
            },
          }),
          onClick: () => {
            if (!this.isMobile()) return;
            this.$store.commit('viewsMenu/setIsOpen', false);
          },
        };
      }).filter(Boolean);
    },
    appViews(appName) {
      const app = this.getAppByName(appName);
      return app?.views?.filter(({ visible }) => visible).map(view => ({ name: view.name, type: this.$t('global.view') })) || [];
    },
    appWorkflows(appName) {
      const app = this.getAppByName(appName);
      return app?.workflows?.map(workflow => ({ name: workflow.name, type: this.$t('global.workflow') })) || [];
    },
    async openDialogUninstallApp(app) {
      await this.loadApps();

      this.openDialog({
        component: 'DialogConfirmAction',
        attrs: {
          warning: true,
          text: this.t('uninstallAppWarningMessage'),
          itemDependencies: [
            ...this.appViews(app.name).map(view => `(${view.type}) ${view.name}`),
            ...this.appWorkflows(app.name).map(workflow => `(${workflow.type}) ${workflow.name}`),
          ],
        },
        on: {
          onConfirm: async () => {
            try {
              this.loading[app.name] = true;

              await this.uninstallApp(app.name);

              await Promise.all([
                this.loadWorkspace(),
                this.fetchData(),
              ]);
            } catch (error) {
              this.$errorRescue(this, error, 'uninstalledApp');
            } finally {
              this.loading[app.name] = false;
            }
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">
.home-member {
  min-height: 100%;
  padding-bottom: calc(var(--z-dock-height) + var(--z-dock-margin));
  position: relative;
  width: 100%;
}

.home-member__page-list {
  background: transparent !important;
  padding: 0;
}

.home-member__page-list-item {
  background: transparent !important;
}

.home-member__app-card-wrapper {
  position: relative;
}

.home-member__app-card-loader {
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(var(--z-theme-surface), 0.3);
  border-radius: var(--z-border-radius-base);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.home-member__app-card-menu {
  position: absolute;
  right: var(--z-s2);
  top: var(--z-s2);
  opacity: 0;
  transition: opacity 0.2s;
}

.home-member__app-card-wrapper:hover .home-member__app-card-menu {
  opacity: 1;
}
</style>
