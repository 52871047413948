<i18n lang="yaml">
pt:
  noData: 'Sem opções selecionadas'
  empty: 'Vazio'
  addItem: 'Adicionar opção'
  addTemplateOptions: 'Adicionar opções de um modelo'
  defineInternalValue: 'Definir valor'
  template:
    name:
      CSAT: 'CSAT'
      Likert: 'Likert'
      NPS: 'NPS'
      Stars: 'Estrelas'
      YesNoMaybe: 'Sim/Não/Talvez'
    option:
      Strongly Disagree: 'Discordo fortemente'
      Disagree: 'Discordo'
      Neutral: 'Neutro'
      Agree: 'Concordo'
      Strongly Agree: 'Concordo fortemente'
      Yes: 'Sim'
      No: 'Não'
      Maybe: 'Talvez'

en:
  noData: 'No options defined'
  empty: 'Empty'
  addItem: 'Add option'
  addTemplateOptions: 'Add options from a template'
  defineInternalValue: 'Define value'
  template:
    name:
      CSAT: 'CSAT'
      Likert: 'Likert'
      NPS: 'NPS'
      Stars: 'Stars'
      YesNoMaybe: 'Yes/No/Maybe'
    option:
      Strongly Disagree: 'Strongly disagree'
      Disagree: 'Disagree'
      Neutral: 'Neutral'
      Agree: 'Agree'
      Strongly Agree: 'Strongly agree'
      Yes: 'Yes'
      No: 'No'
      Maybe: 'Maybe'
</i18n>

<template>
  <div>
    <div class="select-field-editor__label">
      {{ label }}
    </div>
    <div class="select-field-editor__options-wrapper">
      <div
        v-if="selectOptions.length === 0"
        class="text--disabled pa-2"
      >
        {{ t('noData') }}
      </div>
      <DraggableList
        v-else
        v-model="selectOptions"
        dense
        persistent-drag-icon
        list-custom-class="pa-0"
        class="select-field-editor__options-list"
      >
        <template #item-content="{ item, index }">
          <v-row
            v-test-id="`select-option-${item.value || 'empty'}`"
            no-gutters
            class="align-center"
          >
            <v-col>
              <deck-text-field
                :ref="`select-option-value-${index}`"
                :model-value="item.value"
                :placeholder="t('empty')"
                :controls="optionControls"
                dense
                required
                fixed-controls
                @update:model-value="setSelectValue(index, $event)"
                @keypress:enter="newItem('value')"
                @control:click="(...args) => onControlClick(index, ...args)"
              >
                <template #prepend>
                  <DialogCreateOrEditFieldColorPicker
                    :model-value="item.color"
                    class="mr-2 flex-grow-0 flex-shrink-0"
                    @update:model-value="setSelectColor(index, $event)"
                  />
                </template>
              </deck-text-field>
            </v-col>
            <v-col
              v-if="internalValue"
              class="ml-3"
            >
              <deck-text-field
                :ref="`select-option-internal-value-${index}`"
                :model-value="item.internal_value"
                :placeholder="t('defineInternalValue')"
                :type="internalValue === 'Number' ? 'number' : 'text'"
                hide-details
                dense
                @update:model-value="setSelectInternalValue(index, $event)"
                @keypress:enter="newItem('internal-value')"
              />
            </v-col>
          </v-row>
        </template>
      </DraggableList>
    </div>

    <div class="select-field-editor__actions">
      <deck-button
        class="justify-start"
        size="small"
        kind="ghost"
        color="controls"
        icon-prepend="tag"
        :text="t('addItem')"
        @click="newItem(internalValue ? 'internal-value' : 'value')"
      />

      <v-menu
        open-on-click
        location="bottom right bottom"
      >
        <template #activator="{ props }">
          <deck-button
            size="small"
            kind="ghost"
            :aria-label="t('addTemplateOptions')"
            v-bind="props"
            icon-prepend="tags"
            :text="t('addTemplateOptions') "
          />
        </template>

        <v-list>
          <ListItem
            v-for="(templateOption, index) in templateOptions"
            :key="templateOption.text"
            :index="index"
            :item="{
              title: templateOption.text,
              icon: templateOption.icon,
            }"
            @click="addTemplateOptions(templateOption.value)"
          />
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import DialogCreateOrEditFieldColorPicker from '~/components/dialogs/dialog-create-or-edit-field/_color-picker';
import { COLORS, SELECT_OPTIONS_TEMPLATE } from '~/assets/javascript/constants';
import { v4 as uuidv4 } from 'uuid';
import DraggableList from '~/components/DraggableList';
import DeckButton from '~/deck/button';
import DeckTextField from '~/deck/text-field';

const COLOR_KEYS = Object.keys(COLORS);
const DEFAULT_SELECT_OPTIONS = [{ id: uuidv4(), color: COLORS[COLOR_KEYS[0]].base }];

export default {
  name: 'DeckSelectOptionsInput',
  components: {
    DialogCreateOrEditFieldColorPicker,
    DraggableList,
    DeckButton,
    DeckTextField,
    ListItem: defineAsyncComponent(() => import('~/components/list/ListItem')),
  },
  props: {
    modelValue: {
      type: Array,
      default: () => DEFAULT_SELECT_OPTIONS,
    },
    internalValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    let colorIndex = 1;

    colorIndex = this.modelValue.length;

    return {
      colors: COLOR_KEYS,
      colorIndex,
      optionControls: [
        {
          text: this.$t('global.remove'),
          icon: 'trash',
          iconKind: 'regular',
          color: 'destructive',
          controlName: 'remove-select-option',
        },
      ],
      selectOptionsTemplate: Object.entries(SELECT_OPTIONS_TEMPLATE).reduce((acc, [template, { options, translateOptions }]) => {
        acc[template] = options.map((option) => {
          const translationKey = `template.option.${option.value}`;

          return {
            ...option,
            value: translateOptions ? this.t(translationKey) : option.value,
            id: uuidv4(),
          };
        });

        return acc;
      }, {}),
      templateOptions: Object.keys(SELECT_OPTIONS_TEMPLATE).reduce((acc, key) => {
        const translationKey = `template.name.${key}`;

        acc.push({
          text: this.t(translationKey),
          value: key,
          icon: SELECT_OPTIONS_TEMPLATE[key].icon,
        });

        return acc;
      }, []),
    };
  },
  computed: {
    selectOptions: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    onControlClick(index, controlName) {
      if (controlName === 'remove-select-option') {
        this.removeItem(index);
      }
    },
    addTemplateOptions(template) {
      this.selectOptions = [...this.selectOptions, ...this.selectOptionsTemplate[template]];
    },
    newItem(refType = 'value') {
      if (!this.colors[this.colorIndex]) {
        this.colorIndex = 0;
      }

      this.selectOptions = [...this.selectOptions, { id: uuidv4(), color: COLORS[this.colors[this.colorIndex]].base }];

      this.colorIndex++;

      nextTick().then(() => {
        this.focusOnOption(this.selectOptions.length - 1, refType);
      });
    },
    focusOnOption(index, refType = 'value') {
      const deckTextFieldRef = this.$refs[`select-option-${refType}-${index}`];

      if (!deckTextFieldRef) return;

      // Finds the <input> inside deck-text-field and focus on it
      deckTextFieldRef.$el.querySelector('input').focus();
    },
    removeItem(index) {
      this.selectOptions = [
        ...this.selectOptions.slice(0, index),
        ...this.selectOptions.slice(index + 1),
      ];

      this.colorIndex--;
    },
    setSelectValue(index, value) {
      this.selectOptions[index] = { ...this.selectOptions[index], value };
    },
    setSelectColor(index, color) {
      this.selectOptions[index] = { ...this.selectOptions[index], color };
    },
    setSelectInternalValue(index, rawValue) {
      const internalValue = this.internalValue === 'Number' ? Number(rawValue) : rawValue;

      this.selectOptions[index] = { ...this.selectOptions[index], internal_value: internalValue };
    },
  },
};
</script>

<style lang="scss">
.select-field-editor__label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}
.select-field-editor__options-list {
  max-height: 200px;
  overflow-y: auto;
}
.select-field-editor__options-wrapper {
  border: 1px solid #cccccc;
  border-radius: var(--z-border-radius-inner-base);
  padding: 4px;
  width: 100%;
  padding: 8px;
}
.select-field-editor__actions {
  margin-top: 4px;
  padding-bottom: 16px;
  width: 100%;
}
</style>
