<template>
  <div
    class="deck-exodia-header-image__wrapper mb-3"
    :class="{
      [`deck-exodia-header-image__wrapper--${size}`]: true,
      'deck-exodia-header-image__wrapper--full': showProfileImage,
    }"
  >
    <div class="deck-exodia-header-image">
      <v-img
        v-if="showCoverImage && coverImageUrl"
        v-test-id="'deck-exodia-header-cover-image'"
        :class="headerCoverImageStyle"
        :src="coverImageUrl"
        :cover="!coverImageContain"
      />
      <div
        v-else
        :class="headerCoverImageFallbackStyle"
      />

      <template v-if="isProfile">
        <v-img
          v-if="showProfileImage && profileImageUrl"
          v-test-id="'deck-exodia-header-profile-image'"
          :src="profileImageUrl"
          :aspect-ratio="1"
          :class="`deck-exodia-header-image__profile-image deck-exodia-header-image__profile-image--${size}`"
          :cover="!profileImageContain"
        />
        <DeckExodiaProfileImageFallback
          v-else
          v-test-id="'deck-exodia-header-profile-image-fallback'"
          :class="`deck-exodia-header-image__profile-image deck-exodia-header-image__profile-image--${size}`"
          :text="title"
          :rounded="showProfileImage"
          :aspect-ratio="1"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DeckExodiaProfileImageFallback from './_profile-image-fallback';

export default {
  name: 'DeckExodiaHeaderImage',
  components: {
    DeckExodiaProfileImageFallback,
  },
  props: {
    coverImageUrl: { type: String, default: null },
    profileImageUrl: { type: String, default: null },
    showCoverImage: { type: Boolean, default: false },
    showProfileImage: { type: Boolean, default: false },
    title: { type: String, default: null },
    size: { type: String, required: true },
    coverImageContain: { type: Boolean, default: false },
    profileImageContain: { type: Boolean, default: false },
  },
  computed: {
    style() {
      return this.showProfileImage ? 'profile' : 'cover';
    },
    isProfile() {
      return this.style === 'profile';
    },
    headerCoverImageStyle() {
      return {
        'deck-exodia-header-image__cover': true,
        [`deck-exodia-header-image__cover--${this.style}`]: true,
        [`deck-exodia-header-image__cover--${this.size}`]: true,
      };
    },
    headerCoverImageFallbackStyle() {
      return {
        ...this.headerCoverImageStyle,
        'deck-exodia-header-image__cover-fallback': true,
      };
    },
  },
};
</script>

<style lang="scss">
.deck-exodia-header-image__wrapper--small{
  min-height: 154px;
}

.deck-exodia-header-image__wrapper--large{
  height: 200px;
}

.deck-exodia-header-image {
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    justify-content: center;
  }
}

.deck-exodia-header-image__cover {
  height: 164px;
  position: absolute;
  left: 0;
  width: stretch;
}

.deck-exodia-header-image__cover--small.deck-exodia-header-image__cover--profile {
  display: none !important;
}

.deck-exodia-header-image__cover-fallback {
  align-items: center;
  background-color: rgb(var(--v-theme-primary));
  background-position: 50% center;
  background-size: cover;
  opacity: 0.12;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.deck-exodia-header-image__cover--cover {
  height: 184px;
  position: absolute;

  &.deck-exodia-header-image__cover--small {
    border-radius: var(--z-border-radius-inner-base);
    margin: var(--deck-exodia-padding);
    height: 142px;
    width: calc(100% - (var(--deck-exodia-padding) * 2));
  }
}

.deck-exodia-header-image__wrapper--small {
  &:not(.deck-exodia-header-image__wrapper--full){
    margin-top: calc(var(--deck-exodia-padding) * -1);
  }
}
.deck-exodia-header-image__profile-image {
  border-radius: var(--z-border-radius-inner-base);
  flex-grow: 0;
  height: 150px;
  margin-top: 32px;
  width: 150px !important;
  z-index: 1;
}

.deck-exodia-header-image__profile-image--small {
  aspect-ratio: 1;
  height: unset;
  margin-top: 0;
  width: 100% !important;
}
</style>
