import { uniq } from 'lodash';

export const state = () => ({
  favoriteViewIds: [],
  favoriteAppIds: [],
});

export const mutations = {
  addFavoriteViewId(state, viewId) {
    state.favoriteViewIds = uniq([...state.favoriteViewIds, viewId]);
  },
  removeFavoriteViewId(state, viewId) {
    state.favoriteViewIds = state.favoriteViewIds.filter(id => id !== viewId);
  },
  addFavoriteAppId(state, appId) {
    state.favoriteAppIds = uniq([...state.favoriteAppIds, appId]);
  },
  removeFavoriteAppId(state, appId) {
    state.favoriteAppIds = state.favoriteAppIds.filter(id => id !== appId);
  },
};

export const getters = {};
export const actions = {};

export const namespaced = true;
