import ActionButtonInfoComponentBuilder from './action-button';
import ChartInfoComponentOptionBuilder from './chart';
import FieldInfoComponentOptionBuilder from './field';
import FilterBarInfoComponentOptionBuilder from './filter-bar';
import GroupInfoComponentOptionBuilder from './group';
import MarkdownInfoComponentOptionBuilder from './markdown';
import SubpageInfoComponentOptionBuilder from './subpage';

const infoComponentOptionBuilders = {
  'action-button': ActionButtonInfoComponentBuilder,
  chart: ChartInfoComponentOptionBuilder,
  field: FieldInfoComponentOptionBuilder,
  filter_bar: FilterBarInfoComponentOptionBuilder,
  group: GroupInfoComponentOptionBuilder,
  markdown: MarkdownInfoComponentOptionBuilder,
  subpage: SubpageInfoComponentOptionBuilder,
};

export default class InfoComponentOptionBuilder {
  static build(infoComponent, mainContent) {
    return new InfoComponentOptionBuilder(infoComponent, mainContent).build();
  }

  constructor(infoComponent, mainContent = []) {
    this.builder = new infoComponentOptionBuilders[infoComponent.type](infoComponent, mainContent);
  }

  build() {
    return this.builder.build();
  }
}
