import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/default.vue").then(m => m.default || m)),
  dev: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/dev.vue").then(m => m.default || m)),
  home: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/home.vue").then(m => m.default || m)),
  loading: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/loading.vue").then(m => m.default || m)),
  member: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/member.vue").then(m => m.default || m)),
  public: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/public.vue").then(m => m.default || m)),
  store: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/store.vue").then(m => m.default || m)),
  studio: defineAsyncComponent(() => import("/codebuild/output/src3963973704/src/zazos-web/layouts/studio.vue").then(m => m.default || m))
}