export const generateNavigationItems = (items, views, viewId, localePath, tenantSlug) => {
  const generateItems = items => items.map((item) => {
    if (item.items) {
      const items = generateItems(item.items);

      if (item.navigation_type === 'none' || !item.navigation_type) {
        if (items.length === 0) return undefined;

        return {
          draggable: false,
          id: item.id,
          title: item.group_name,
          active: items.some(i => i.active),
          items,
        };
      }

      // If the navigation type is none sidebar,
      // we should return the first view of the group or nothing
      let firstViewId = null;

      const findScopedView = (items) => {
        items.forEach((item) => {
          if (firstViewId) return;

          if (item.items) {
            findScopedView(item.items);
            return;
          }

          const view = views.find(({ id }) => id === item.id);
          firstViewId = view?.id;
        });
      };

      findScopedView(item.items);

      if (!firstViewId) return null;

      // It will be active if the current view belongs to the group
      let active = false;

      const findActiveView = (items) => {
        items.forEach((item) => {
          if (active) return;

          if (item.items) {
            findActiveView(item.items);
            return;
          }

          if (item.id === viewId) {
            active = true;
          }
        });
      };

      findActiveView(item.items);

      return {
        draggable: false,
        id: firstViewId,
        title: item.group_name,
        to: localePath({
          name: 't-tenantSlug-m-viewId',
          params: { tenantSlug, viewId: firstViewId },
        }),
        active,
      };
    }

    const view = views.find(({ id }) => id === item.id);

    if (!view) return null;

    return {
      draggable: false,
      id: view.id,
      title: view.page_name,
      to: localePath({
        name: 't-tenantSlug-m-viewId',
        params: { tenantSlug, viewId: view.id },
      }),
      active: view.id === viewId,
    };
  }).filter(Boolean);

  return generateItems(items);
};
