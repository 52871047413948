import type { GetterTree, MutationTree, ActionTree } from 'vuex';

export const state = () => ({
  barNotApplicable: true, // If category is not trial, do not show paywall bar
  barHiddenByUser: false, // If user closes bar, do not show it in the same session
});

type State = ReturnType<typeof state>;

export const getters = <GetterTree<State, any>>{
  barHidden: state => state.barNotApplicable || state.barHiddenByUser,
};

export const mutations = <MutationTree<State>>{
  setBarNotApplicable(state, status) {
    state.barNotApplicable = status;
  },
  setBarHiddenByUser(state, hidden) {
    state.barHiddenByUser = hidden;
  },
};

export const actions = <ActionTree<State, any>>{
  async loadBarState({ commit }) {
    const paywallBarHidden = await JSON.parse(sessionStorage.getItem('hidePaywallBar') || 'false');
    commit('setBarHiddenByUser', paywallBarHidden);
  },
  hideBar({ commit }) {
    commit('setBarHiddenByUser', true);
    sessionStorage.setItem('hidePaywallBar', 'true');
  },
};

export const namespaced = true;
