export function isIdPresentInRecursiveList(item, id) {
  if (!item) return false; // Empty item

  if (item.id === id) return true; // Found the id at the current level

  // Recursively check each item in the 'items' array
  if (item.items) return item.items.some(subItem => isIdPresentInRecursiveList(subItem, id));

  // Recursively check each item in the array
  if (Array.isArray(item)) return item.some(subItem => isIdPresentInRecursiveList(subItem, id));

  return false; // Id not found
}

export const firstItemIdInRecursiveList = (list, index = 0) => {
  if (!list) return null;

  if (Array.isArray(list)) {
    if (index >= list.length) return null;
    const item = list[index];

    if (item.items) {
      const id = firstItemIdInRecursiveList(item.items);

      if (id) return id;
    } else if (item.id) {
      return item.id;
    }

    return firstItemIdInRecursiveList(list, index + 1); // Recursively check the next item
  }

  if (list.items) return firstItemIdInRecursiveList(list.items);
  if (list.id) return list.id;
  return null;
};

export const insertItemInPosition = (list, item, position) => {
  const newList = [...list];
  newList.splice(position, 0, item);
  return newList;
};

export const removeItemFromList = (list, item) => {
  const newList = [...list];
  const index = newList.indexOf(item);

  if (index === -1) return list;

  newList.splice(index, 1);
  return newList;
};

export const changeItemPosition = (list, oldPosition, newPosition) => {
  const item = list[oldPosition];
  const newList = removeItemFromList(list, item);
  return insertItemInPosition(newList, item, newPosition);
};
