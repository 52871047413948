const ROUTES = {
  studio: {
    views: {
      routeName: 't-tenantSlug-studio-pages',
    },
    view: {
      routeName: 't-tenantSlug-studio-pages-id',
      viewId: 'id',
    },
    sheets: {
      routeName: 't-tenantSlug-studio-data',
    },
    sheet: {
      routeName: 't-tenantSlug-studio-data-id',
      sheetId: 'id',
    },
    workflows: {
      routeName: 't-tenantSlug-studio-automations',
    },
    workflow: {
      routeName: 't-tenantSlug-studio-automations-id',
      workflowId: 'id',
    },
    settings: {
      routeName: 't-tenantSlug-studio-settings',
      routeKey: 'studio-settings',
      authentication: {
        routeName: 't-tenantSlug-studio-settings-authentication',
        routeKey: 'studio-settings-authentication',
      },
      globalParametersManager: {
        routeName: 't-tenantSlug-studio-settings-global-parameters-manager',
        routeKey: 'studio-settings-global-parameters',
      },
      integrations: {
        routeName: 't-tenantSlug-studio-settings-integrations',
        routeKey: 'studio-settings-integrations',
      },
      integration: {
        routeName: 't-tenantSlug-studio-settings-integrations-providerSlug',
        providerSlug: 'providerSlug',
        routeKey: 'studio-settings-integration',
      },
      tokens: {
        routeName: 't-tenantSlug-studio-settings-tokens',
        routeKey: 'studio-settings-tokens',
      },
      userGroups: {
        routeName: 't-tenantSlug-studio-settings-user-groups',
        routeKey: 'studio-settings-users-groups',
      },
      userSignup: {
        routeName: 't-tenantSlug-studio-settings-user-signup',
        routeKey: 'studio-settings-user-sign-up',
      },
      users: {
        routeName: 't-tenantSlug-studio-settings-users',
        routeKey: 'studio-settings-users',
      },
    },
  },
  member: {
    routeName: 't-tenantSlug-m',
    accountConfiguration: {
      routeName: 't-tenantSlug-m-account-configuration',
      routeKey: 'member-account-configuration',
    },
    views: {
      routeName: 't-tenantSlug-m',
    },
    view: {
      routeName: 't-tenantSlug-m-viewId',
      viewId: 'viewId',
      record: {
        routeName: 't-tenantSlug-m-viewId-r-recordId',
        viewId: 'viewId',
        new: {
          routeName: 't-tenantSlug-m-viewId-r-new',
          viewId: 'viewId',
        },
      },
    },
  },
  public: {
    views: {
      routeName: 't-tenantSlug-p',
    },
    view: {
      routeName: 't-tenantSlug-p-viewId',
      viewId: 'viewId',
      record: {
        routeName: 't-tenantSlug-p-viewId-r-recordId',
        viewId: 'viewId',
      },
    },
    emailValidation: {
      routeName: 't-tenantSlug-email-validation',
    },
    login: {
      routeName: 't-tenantSlug-login',
    },
  },
  store: {
    routeName: 't-tenantSlug-store',
  },
};

export function findRouteByKey(key, routes = ROUTES) {
  const entries = Object.entries(routes);

  const find = ([_routeName, routeValue]) => {
    if (routeValue.routeKey === key) {
      return routeValue;
    }

    if (typeof routeValue === 'object') {
      const found = findRouteByKey(key, routeValue);

      if (found) {
        return found;
      }
    }

    return null;
  };

  return entries.map(find).find(result => result !== null) || null;
}

export default ROUTES;

export const SHOW_RECORDS_ROUTES = [
  ROUTES.member.view.record,
  ROUTES.public.view.record,
];

export const NEW_RECORDS_ROUTES = [
  ROUTES.member.view.record.new,
];

const removeLocaleFromRoute = route => route.split('___')[0];

export function isChangingBetweenRoutes({ from, to, routes }) {
  const fromAndTo = [removeLocaleFromRoute(from), removeLocaleFromRoute(to)];

  return routes.every(route => fromAndTo.includes(removeLocaleFromRoute(route)));
}
