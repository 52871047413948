import { ref, computed } from 'vue';
import { useStorage } from '@vueuse/core';

export const ZAZOS_STORAGE_KEY = 'zazos-storage';

const LOGOS = {
  light: '/zazos-text.svg',
  dark: '/zazos-text-white.svg',
};

const DARK_MODE_FROM_THEME = {
  // system: () => (window.matchMedia
  //   ? window.matchMedia('(prefers-color-scheme: dark)').matches
  //   : false),
  // False until we are ready to support dark mode
  system: () => false,
  light: () => false,
  dark: () => true,
};

const storage = useStorage(ZAZOS_STORAGE_KEY, { theme: null }); // use null theme to indicate that the user has not set a theme yet
const theme = ref(storage.value.theme || 'light');
const darkMode = ref(DARK_MODE_FROM_THEME[theme.value]?.());
const logoPath = computed(() => (darkMode.value ? LOGOS.dark : LOGOS.light));

export function useZazos() {
  const { $store } = useNuxtApp();

  const defaultTheme = computed(() => $store?.getters['workspace/defaultTheme']); // optional chaining just to avoid mocking in all tests
  const vuetifyTheme = useTheme();

  const setTheme = (newTheme: string, { cache = true } = {}) => {
    theme.value = newTheme;
    darkMode.value = DARK_MODE_FROM_THEME[newTheme]?.();
    if (cache) storage.value.theme = newTheme;

    // Update the Vuetify theme
    vuetifyTheme.global.name.value = darkMode.value ? 'dark' : 'light';
  };

  watch(defaultTheme, (value) => {
    // we cache the value set by the user
    // if the cache is empty, we set the default workspace theme
    if (!storage.value.theme) setTheme(value);
  });

  // Method to change the theme
  watch(theme, value => setTheme(value));

  watch(() => storage.value.theme, (value) => {
    if (value && theme.value !== value) setTheme(value);
  });

  return {
    theme,
    darkMode,
    logoPath,
    darkLogoPath: LOGOS.dark,
    lightLogoPath: LOGOS.light,
    setTheme,
    initializeTheme: () => setTheme(theme.value, { cache: false }),
  };
}
