import type { NuxtApp } from '#app';
import { LoginSetup } from './login';
import { LogoutSetup } from './logout';
import { OnRedirectSetup } from './on-redirect';
import { ResetPasswordSetup } from './reset-password';
import { WorkspaceSetup } from './workspace';

export const generatePlugin = (nuxt: NuxtApp) => {
  OnRedirectSetup(nuxt);
  LoginSetup(nuxt);
  LogoutSetup(nuxt);
  ResetPasswordSetup(nuxt);
  WorkspaceSetup(nuxt);
};
