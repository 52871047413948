import { useNuxtApp } from '#app';
import type { MutationTree, ActionTree } from 'vuex';
import { enrichViewData, generateNavigationItems } from '~/assets/javascript/utils';

export const state = () => ({
  views: [],
  miniSidebar: window.localStorage?.getItem('viewsMenu.miniSidebar') || false,
  isOpen: true,
  search: '',
});

type State = ReturnType<typeof state>;

export const getters = {
  viewsOrder: (_state, _getters, _rootState, rootGetters) => rootGetters['workspace/viewsOrder'],
  hiddenViewsOrder: (_state, _getters, _rootState, rootGetters) => rootGetters['workspace/hiddenViewsOrder'],
  postInstallationSetupViewsOrder: (_state, _getters, _rootState, rootGetters) => rootGetters['workspace/postInstallationSetupViewsOrder'],
  viewById: ({ views }) => _memoize(viewId => views.find(view => view.id === viewId)),
  isViewInsideStepper: (_, { generateViewNavigationPath }) => _memoize((viewId) => {
    const navigationGroupPath = generateViewNavigationPath(viewId);

    return navigationGroupPath.some(item => item.navigation_type === 'stepper');
  }),
  hasStepperNext: (_, { generateViewNavigationPath }) => _memoize((viewId) => {
    const navigationGroupPath = generateViewNavigationPath(viewId);
    const lastGroup = navigationGroupPath[navigationGroupPath.length - 1];
    const viewIndex = lastGroup.items.findIndex(item => item.id === viewId);
    return viewIndex < lastGroup.items.length - 1;
  }),
  stepperNextViewId: (_, { generateViewNavigationPath }) => _memoize((viewId) => {
    const navigationGroupPath = generateViewNavigationPath(viewId);
    const lastGroup = navigationGroupPath[navigationGroupPath.length - 1];
    const viewIndex = lastGroup.items.findIndex(item => item.id === viewId);
    return lastGroup.items[viewIndex + 1].id;
  }),
  hasStepperPrevious: (_, { generateViewNavigationPath }) => _memoize((viewId) => {
    const navigationGroupPath = generateViewNavigationPath(viewId);
    const lastGroup = navigationGroupPath[navigationGroupPath.length - 1];
    const viewIndex = lastGroup.items.findIndex(item => item.id === viewId);
    return viewIndex > 0;
  }),
  stepperPreviousViewId: (_, { generateViewNavigationPath }) => _memoize((viewId) => {
    const navigationGroupPath = generateViewNavigationPath(viewId);
    const lastGroup = navigationGroupPath[navigationGroupPath.length - 1];
    const viewIndex = lastGroup.items.findIndex(item => item.id === viewId);
    return lastGroup.items[viewIndex - 1].id;
  }),
  generateViewNavigationItems: (_state, { viewsOrder }, _rootState, rootGetters) => (viewId, localePath, tenantSlug) => generateNavigationItems(viewsOrder, rootGetters['workspace/visibleViewsInCurrentScope'], viewId, localePath, tenantSlug),
  generateViewNavigationPath: (_, { viewsOrder, hiddenViewsOrder, postInstallationSetupViewsOrder }) => _memoize((viewId) => {
    const navigationGroupPath = [];
    let viewFound = false;

    const findView = (items, viewId) => {
      items.forEach((item) => {
        if (viewFound) return;

        if (item.id === viewId) {
          viewFound = true;
          return;
        }

        if (item.items) {
          navigationGroupPath.push(item);
          findView(item.items, viewId);
          if (viewFound) return;
          navigationGroupPath.pop();
        }
      });
    };

    findView([...viewsOrder, ...hiddenViewsOrder, ...postInstallationSetupViewsOrder], viewId);

    return navigationGroupPath;
  }),
};

export const mutations = <MutationTree<State>>{
  setIsOpen(state, isOpen) {
    state.isOpen = isOpen;
  },
  updateViews(state, { views }) {
    state.views = views;
  },
  resetFilterToolbarSearch(state) {
    state.search = '';
  },
};

export const actions = <ActionTree<State, any>>{
  async loadViews({ commit, rootGetters }, { tenantSlug = null, isPublic = false }) {
    const { $api } = useNuxtApp();
    const path = isPublic ? `/public/workspaces/${tenantSlug}/views` : '/views';
    const views = await $api.$get(path);

    const categories = rootGetters['workspace/categoryHierarchy'];

    const enrichedViews = enrichViewData(views, categories);

    commit('updateViews', { views: enrichedViews });
  },
};

export const namespaced = true;
