export const ENVIRONMENT_DEFINITIONS = {
  member: {
    analyticsTrack: 'switchToMemberView',
    environment: 'member',
    routeIdParam: 'viewId',
    routeWithId: 't-tenantSlug-m-viewId',
    recordRoute: 't-tenantSlug-m-viewId-r-recordId',
    newRecordRoute: 't-tenantSlug-m-viewId-r-new',
    standardRoute: 't-tenantSlug-m',
    icon: 'fa-users-rectangle fa-regular',
    mandatoryTenantSlug: true,
  },
  public: {
    analyticsTrack: 'switchToPublicView',
    environment: 'public',
    routeIdParam: 'viewId',
    routeWithId: 't-tenantSlug-p-viewId',
    recordRoute: 't-tenantSlug-p-viewId-r-recordId',
    standardRoute: 't-tenantSlug-p',
    icon: 'fa-globe fa-regular',
    mandatoryTenantSlug: true,
  },
  studio: {
    analyticsTrack: 'switchToBuilderView',
    environment: 'studio',
    routeIdParam: 'id',
    routeWithId: 't-tenantSlug-studio-pages-id',
    standardRoute: 't-tenantSlug-studio-data',
    icon: 'fa-pen-ruler fa-regular',
    mandatoryTenantSlug: true,
  },
  appStore: {
    analyticsTrack: 'switchToAppStoreView',
    environment: 'appStore',
    standardRoute: 'store',
    icon: 'fa-store fa-regular',
  },
};

export const state = () => ({
  currentEnvironment: null,
  currentEnvironmentIcon: null,
  inIframe: window.self !== window.top,
});

export const mutations = {
  setCurrentEnvironment(state, environment) {
    if (environment in ENVIRONMENT_DEFINITIONS) {
      state.currentEnvironment = environment;
      state.currentEnvironmentIcon = ENVIRONMENT_DEFINITIONS[environment].icon;
    }
  },
};

export const getters = {
  isPublicEnvironment: state => state.currentEnvironment === 'public',
  isMemberEnvironment: state => state.currentEnvironment === 'member',
  isStudioEnvironment: state => state.currentEnvironment === 'studio',
  isAppStoreEnvironment: state => state.currentEnvironment === 'appStore',
};

export const namespaced = true;
