<template>
  <LogoLoading />
</template>

<script lang="ts">
import ROUTES from '~/assets/javascript/constants/routes';
import LogoLoading from '~/components/LogoLoading';

export default defineComponent({
  name: 'PagesTenantStudioDataIndex',
  components: { LogoLoading },
  setup() {
    definePageMeta({
      layout: false,
      middleware: [
        async () => {
          const { $api, $store, $router } = useNuxtApp();
          const localePath = useLocalePath();
          const firstSheetId = $store.getters['workspace/firstSheetId'] || $store.getters['workspace/allSheetIds'][0];

          if (firstSheetId) {
            return navigateTo(localePath({
              name: ROUTES.studio.sheet.routeName,
              params: {
                tenantSlug: $router.currentRoute.value.params.tenantSlug,
                id: firstSheetId,
              },
            }));
          }

          const allData = await $api.$get('/sheets');
          const peopleSheetId = allData.find(sheet => sheet.system_name === 'people').id;

          return navigateTo(localePath({
            name: ROUTES.studio.sheet.routeName,
            params: {
              tenantSlug: $router.currentRoute.value.params.tenantSlug,
              id: peopleSheetId,
            },
          }));
        },
      ],
    });
  },
});
</script>
