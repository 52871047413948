<template>
  <div class="h-full">
    <p
      v-if="fetchData.viewLoadError"
      class="ma-8 pa-6 rounded bg-white"
    >
      {{ fetchData.viewLoadError }}
    </p>

    <TheLandscape
      v-else
      ref="landscape"
      :can-create="canCreate"
      :can-delete="canDelete"
      :can-edit="canEdit"
      :exodia-props="fetchData.exodiaProps"
      :loading="pending"
      :fields="fetchData.columns"
      :record-id="fetchData.recordId"
      :view="view"
      :page-state-component-key="pageStateComponentKey"
      :outlined="false"
      filter-main-content
      show-workflow
      class="h-full"
      @refetch="refresh"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from '~/assets/javascript/modules/vuex';
import viewChannelMixin from '~/mixins/viewChannelMixin';
import { VIEW_TYPES } from '~/assets/javascript/constants';
import ROUTES from '~/assets/javascript/constants/routes';
import { useView, useViewFetch } from '~/composables/member/useViewFetch';

export default defineComponent({
  name: 'MemberViewRecordShow',
  components: {
    TheLandscape: defineAsyncComponent(() => import('~/components/views/the-landscape')),
  },
  mixins: [
    viewChannelMixin(),
  ],
  async setup() {
    definePageMeta({
      layout: 'member',
      middleware: ['enforce-app-dependencies'],
    });

    const vuex = {
      ...mapState('view', ['view']),
      ...mapGetters('view', ['canCreate', 'canEdit', 'canDelete']),
      ...mapGetters('records', ['hasChangesInDraftRecords']),
    };

    useConfirmLeave({
      warnRouteLeave() {
        return vuex.hasChangesInDraftRecords.value;
      },
      warnPageClose() {
        return vuex.hasChangesInDraftRecords.value;
      },
    });

    const { view } = useView();
    const { $localePath, $router } = useNuxtApp();
    const route = useRoute();

    const { data: fetchData, status: fetchStatus, refresh } = await useLazyAsyncData(async () => {
      const currentPath = $router.currentRoute.value.fullPath;
      const { viewLoadError, columns } = await useViewFetch();
      const {
        recordId,
        record,
        exodiaProps,
        records,
      } = useRecordPage(ROUTES.member.view.record.routeName);

      const redirectToIndex = () => $router.push($localePath({ name: 't-tenantSlug-m-viewId', params: { tenantSlug: route.params.tenantSlug, viewId: view.value.id } }));
      const userChangedTheRoute = currentPath !== $router.currentRoute.value.fullPath;

      if (!recordId && !userChangedTheRoute) return redirectToIndex();
      if ((view.value.page_type === 'Timeline' || !record.value) && !userChangedTheRoute) redirectToIndex();

      useTrackAppUsage('show');

      return {
        columns,
        exodiaProps,
        record,
        recordId,
        records,
        viewLoadError,
      };
    }, {
      default() {
        return {
          columns: [],
          viewLoadError: null,
          exodiaProps: {},
          record: null,
          recordId: null,
          records: [],
        };
      },
    });

    return {
      fetchData,
      fetchStatus,
      view,
      refresh,
      ...vuex,
    };
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-unused-properties
      viewChannelMixin__loadViewAfterUnpause: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    viewChannelMixin__view() {
      return this.view;
    },
    // eslint-disable-next-line vue/no-unused-properties
    viewChannelMixin__onlyRefreshForRecord() {
      return ['Form', 'Cards'].includes(this.view.page_type);
    },
    pending() {
      return this.fetchStatus === 'pending';
    },
    openRecordMode() {
      return VIEW_TYPES[this.view.page_type]?.components.find(
        component => component.key === 'single_item',
      )?.main_section?.openRecordMode || 'none';
    },
    pageStateComponentKey() {
      return this.openRecordMode === 'dialog' ? 'index' : 'single_item';
    },
  },
  watch: {
    hasChangesInDraftRecords: {
      handler(value) {
        this.viewChannelMixin__isPaused = value;
      },
      immediate: true,
    },
  },
  async mounted() {
    // TODO: with TheLandscape now able to render both indexes and records, we
    // should deal with saving scroll positions in a more generic way over there
    if (this.view.page_type !== 'Kanban') return;
    if (!window.sessionStorage.getItem('kanbanScrollPosition')) return;

    // We need to wait for computeds and refs to be ready
    // 3 nextTick was necessary because we use a dynamic component and all the states to be updated
    await nextTick();
    await nextTick();
    await nextTick();
    const contentElement = this.$refs.landscape?.$refs?.content?.$el;
    if (!contentElement) return;
    const scrollPosition = JSON.parse(window.sessionStorage.getItem('kanbanScrollPosition'));
    contentElement.scrollLeft = scrollPosition.scrollLeft;
    contentElement.scrollTop = scrollPosition.scrollTop;
  },
});
</script>
