
import { pick } from 'lodash';
import { HELPER_TEXT_ATTRIBUTES, LABEL_ATTRIBUTES, MINICARD_ITEMS_ATTRIBUTE, MINICARD_LIST_STYLE_ATTRIBUTE } from '~/assets/javascript/constants';
import InfoComponentFromOptionBuilder from '.';

export default class FieldInfoComponentFromOptionBuilder {
  static build(infoComponentOption) {
    return new FieldInfoComponentFromOptionBuilder(infoComponentOption).build();
  }

  constructor(infoComponentOption) {
    this.fieldInfoComponentOption = infoComponentOption;
  }

  build() {
    return {
      ...pick(
        this.fieldInfoComponentOption,
        [
          'avatar_image',
          'columns',
          'cover_image',
          'exhibition_mode',
          'field_id',
          'field_type',
          'format_option',
          'id',
          'items_style',
          'items_subtitle',
          'items_title',
          'list_style',
          'permit_links_editing',
          'permit_record_delete',
          'permit_record_insert',
          'permit_record_update',
          'profile_image',
          'records_template',
          'required',
          'rows',
          'sort_by',
          'sort_by_label',
          'type',
          'uneditable',
          'filter_bar_id',
          'view_type',
          MINICARD_ITEMS_ATTRIBUTE,
          MINICARD_LIST_STYLE_ATTRIBUTE,
          ...HELPER_TEXT_ATTRIBUTES,
          ...LABEL_ATTRIBUTES,
        ],
      ),
      items: this.fieldInfoComponentOption.items?.map(
        infoComponentOption => InfoComponentFromOptionBuilder.build(infoComponentOption),
      ) || [],
    };
  }
}
