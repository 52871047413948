import { v4 as uuidv4 } from 'uuid';

export default class SubpageInfoComponentOptionBuilder {
  static build(infoComponent) {
    return new SubpageInfoComponentOptionBuilder(infoComponent).build();
  }

  constructor(infoComponent) {
    this.subpageInfoComponent = infoComponent;
  }

  build() {
    const infoComponentId = this.subpageInfoComponent.id || uuidv4();

    const subpageInfoComponentOption = {
      ...this.subpageInfoComponent,
      id: infoComponentId,
      title: this.subpageInfoComponent.label,
      titleTypography: 'text-body-2',
      icon: 'fa-regular fa-sitemap',
    };

    return subpageInfoComponentOption;
  }
}
