const permissionCompliesWithScope = (permission, scope, user) => {
  if (scope.key === 'everyone') {
    // the default scope is a little bit different, it should show views that are
    // - shared with everyone
    // - shared with the current user
    // - shared with a group that is not a navigation scope
    return permission.everyone // shared with everyone
    || (
      permission.grantee // or have a grantee that is either
      && (
        permission.grantee.id === user.id // this specific user
        || ( // or a group that is not a navigation scope. This case falls back to the default scope
          permission.grantee_type === 'UserGroup'
          && !permission.grantee.use_as_navigation_scope
        )
      )
    );
  }

  // Here, the permission can be everyone, a user or a group
  // If it's everyone, the grantee will be null and that is ok
  // because the scope is not everyone
  return permission.grantee?.id === scope.key;
};

export const viewBelongsToScope = (view, currentScope, currentUser) => (
  // Public views does not have the permissions field
  (view.permissions || []).some(permission => permissionCompliesWithScope(permission, currentScope, currentUser))
);

export const viewsInScope = (allViews, currentScope, currentUser) => allViews.filter(view => viewBelongsToScope(view, currentScope, currentUser));

export const viewToRedirectAfterScopeChange = (allViews, currentViewId, currentCategoryName, currentScope, currentUser) => {
  if (!currentViewId) return null;

  const viewsInScope = allViews.filter(view => viewBelongsToScope(view, currentScope, currentUser));

  // If there are no views in the current scope, user must not be able to change the scope
  if (viewsInScope.length === 0) throw new Error('No views in the current scope', { currentScope, currentUser });

  // If the current view is in the scope, we don't need to redirect
  if (viewsInScope.some(v => v.id === currentViewId)) return null;

  return viewsInScope.find(v => v.category.category_name === currentCategoryName) // first view in the same category
  || viewsInScope[0]; // first view in the scope list
};

export const getFirstViewIdFromCategory = (category) => {
  let firstView;

  const findFirstView = (viewsOrder) => {
    if (firstView) return;

    if (viewsOrder.items && viewsOrder.items.length > 0) {
      viewsOrder.items.forEach(findFirstView);
    } else {
      firstView = viewsOrder;
    }
  };

  category.views?.forEach(findFirstView);

  return firstView?.id;
};

// if view is shared with a group that is a scope, we should return it
// if not, it is under the umbrella of the default scope (shared with everyone or shared with the user directly or shared with a group that is not a scope)
export const firstAvailableScopeForView = (view, availableScopes, currentUser) => availableScopes.find(scope => viewBelongsToScope(view, scope, currentUser)) || availableScopes.find(({ key }) => key === 'everyone');
