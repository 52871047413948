import ActionCableVue from 'actioncable-vue';
import ZazosCable from '~/assets/javascript/modules/cable/zazos-cable';

export default defineNuxtPlugin({
  name: 'actioncable-vue',
  dependsOn: ['auth'],
  setup(nuxtApp) {
    const { public: { actionCableHost } } = useRuntimeConfig();
    const { vueApp } = nuxtApp;

    vueApp
      .use(ActionCableVue, {
        debug: process.env.ROLLBAR_ENV !== 'production',
        connectImmediately: false,
      });

    return {
      provide: {
        zazosCable: new ZazosCable(
          actionCableHost,
          nuxtApp,
        ),
      },
    };
  },
});
