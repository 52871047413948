import LoginCallback from '../callback';
import { last } from 'lodash';

export class Module {
  constructor(nuxt) {
    this.$api = nuxt.$api;
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.$router = nuxt.$router;
    this.callbackHandler = new LoginCallback(nuxt);
  }

  get route() {
    return this.$router.currentRoute.value;
  }

  async login({ token, redirectTo = null }) {
    await this.$auth.loginWith('local', {
      url: 'authentication/magic_link',
      method: 'post',
      body: { token },
    });

    delete this.route.query.token;

    return this.callbackHandler.handle({ withQuery: true, redirectTo });
  }

  async send({ email }) {
    // In case login has redirect to query param, e.g.: `redirectTo=/t/tenant/install/app`
    const redirectToApp = last(this.route.query?.redirectTo?.split('/')) || null;
    const app = this.route.query?.utm_app || redirectToApp;

    await this.$recaptcha.fetchToken(async token => this.$api
      .post('authentication/magic_link/send', {
        body: {
          email,
          tenant_slug: this.route.params.tenantSlug,
          app,
          recaptcha_token: token,
        },
      }));
  }
}

export function SetupMagicLink(nuxt) {
  nuxt.$auth.magicLinkModule = new Module(nuxt);
}
