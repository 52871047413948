import orderBy from 'lodash/orderBy';
import FIELDS from './fields';
import TRIGGERS from './triggers';
import STEPS from './steps';
import VIEWS from './views';
import PAGE_STATE_TYPES from './pageStates';

function sortByPosition(obj) {
  return Object.fromEntries(orderBy(Object.entries(obj), ([_, value]) => value.position));
}

export const STEP_TYPES = sortByPosition(STEPS);
export const FIELD_TYPES = sortByPosition(FIELDS);
export const TRIGGER_TYPES = sortByPosition(TRIGGERS);
export const VIEW_TYPES = sortByPosition(VIEWS);

export const AVAILABLE_TYPES = Object.keys(FIELD_TYPES).filter(type => !['User'].includes(type));
export const AVAILABLE_TRIGGER_TYPES = Object.keys(TRIGGER_TYPES);
export const AVAILABLE_VIEW_TYPES = Object.keys(VIEW_TYPES);
export const AVAILABLE_PAGE_STATE_TYPES = Object.keys(PAGE_STATE_TYPES);

export const NON_SHEET_BASED_VIEW_TYPES = ['Content'];
export const SHEET_BASED_VIEW_TYPES = Object.keys(VIEW_TYPES).filter(type => !NON_SHEET_BASED_VIEW_TYPES.includes(type));
export const PUBLIC_VIEW_TYPES = AVAILABLE_VIEW_TYPES.filter(type => VIEW_TYPES[type].canBePublic);

const AVAILABLE_TRIGGERS_BY_VIEW_TYPE = {
  Cards: ['UserRequest', 'ScheduleLegacy', 'UserViewButtonAction'],
  Table: ['UserRequest', 'ScheduleLegacy', 'UserViewButtonAction'],
  Form: ['UserRequest', 'FormSubmit', 'UserViewButtonAction'],
  Timeline: ['UserRequest', 'FormSubmit', 'UserViewButtonAction'],
  Kanban: ['UserRequest', 'UserViewButtonAction'],
  Content: ['UserViewButtonAction'],
  Matrix: ['UserRequest', 'UserViewButtonAction'],
  Calendar: ['UserRequest', 'UserViewButtonAction'],
  Report: ['UserViewButtonAction'],
  Tree: ['UserRequest', 'UserViewButtonAction'],
};

const DEFAULT_TRIGGER_CONFIG = {
  viewTypes: [],
  sheetBased: false,
  viewBased: false,
};

const triggerConfigConstructor = (params = {}) => ({
  ...DEFAULT_TRIGGER_CONFIG,
  ...params,
});

const TRIGGER_CONFIG = {
  RecordCreated: triggerConfigConstructor({
    sheetBased: true,
  }),
  RecordUpdated: triggerConfigConstructor({
    sheetBased: true,
  }),
  RecordDeleted: triggerConfigConstructor({
    sheetBased: true,
  }),
  UserSignedUp: triggerConfigConstructor({
    sheetBased: true,
  }),
  UserRequest: triggerConfigConstructor({
    viewTypes: Object.keys(AVAILABLE_TRIGGERS_BY_VIEW_TYPE).filter(type => !['Content', 'Report'].includes(type)),
    viewBased: true,
  }),
  FormSubmit: triggerConfigConstructor({
    viewTypes: ['Form', 'Timeline'],
    viewBased: true,
  }),
  ScheduleLegacy: triggerConfigConstructor({
    viewTypes: ['Cards', 'Table'],
    viewBased: true,
  }),
  Schedule: triggerConfigConstructor(),
  Workflow: triggerConfigConstructor(),
  WorkflowRequest: triggerConfigConstructor(),
  UserViewButtonAction: triggerConfigConstructor({
    viewTypes: Object.keys(AVAILABLE_TRIGGERS_BY_VIEW_TYPE),
    viewBased: true,
  }),
};

export {
  AVAILABLE_TRIGGERS_BY_VIEW_TYPE,
  PAGE_STATE_TYPES,
  TRIGGER_CONFIG,
};
