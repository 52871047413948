export class Module {
  constructor(nuxt) {
    this.$api = nuxt.$api;
    this.$recaptcha = nuxt.$recaptcha;
    this.$router = nuxt.$router;
  }

  get route() {
    return this.$router.currentRoute.value;
  }

  get tenantSlug() {
    return this.route.params.tenantSlug;
  }

  async sendResetEmail({ email }) {
    return this.$recaptcha.fetchToken(async token => this
      .$api.post('authentication/password_resets', {
        body: {
          email,
          tenant_slug: this.tenantSlug,
          recaptcha_token: token,
        },
      }));
  }

  async resetPassword({ currentPassword, newPassword, resetToken }) {
    return this.$recaptcha.fetchToken(token => this
      .$api.patch('authentication/password_reset', {
        body: {
          token: resetToken,
          current_password: currentPassword,
          new_password: newPassword,
          recaptcha_token: token,
        },
      }));
  }
}

export const ResetPasswordSetup = (nuxt) => {
  nuxt.$auth.resetPasswordModule = new Module(nuxt);
};
