import { pick } from 'lodash';
import { HELPER_TEXT_ATTRIBUTES, LABEL_ATTRIBUTES } from '~/assets/javascript/constants';

export default class ActionButtonInfoComponentFromOptionBuilder {
  static build(infoComponentOption) {
    return new ActionButtonInfoComponentFromOptionBuilder(infoComponentOption).build();
  }

  constructor(infoComponentOption) {
    this.actionButtonInfoComponentOption = infoComponentOption;
  }

  build() {
    return {
      ...pick(
        this.actionButtonInfoComponentOption,
        [
          'columns',
          'params',
          'id',
          'type',
          'action',
          'kind',
          ...HELPER_TEXT_ATTRIBUTES,
          ...LABEL_ATTRIBUTES,
        ],
      ),
    };
  }
}
