import type { GetterTree, MutationTree, ActionTree } from 'vuex';
import { isEmpty, uniq } from 'lodash';
import type { IFetchError } from 'ofetch';
import { isIdPresentInRecursiveList, viewBelongsToScope, viewsInScope } from '~/assets/javascript/utils';
import { THEME_COLORS } from '~/assets/javascript/constants';

const LANGUAGE_LOCALE_MAP = {
  pt: 'pt-BR',
  en: 'en',
};

const getDefaultScope = () => ({
  key: 'everyone',
  title: useNuxtApp().$i18n.t('workspace.scopes.everyone'),
  icon: 'fa-regular fa-user-group-simple',
});

const skipNextLoadWorkspaceCall = ref(false);

function handleWorkspaceLoadError(error: any | IFetchError) {
  const { $notifier, $router, $localePath, $i18n } = useNuxtApp();

  if (error.response?.status === 404) {
    $notifier.showMessage({
      content: $i18n.t('global.workspaceNotFound'),
      color: 'error',
    });

    $router.push($localePath({ name: 'login' }));
  }

  // Auth callback will handle this at
  // assets/javascript/modules/auth/on-redirect/index.js
  if (error.response?.status === 401) return;

  throw error;
}

const computeContrastTextColor = (hexColor: string) => {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return (yiq >= 128) ? 'var(--z-theme-black)' : 'var(--z-theme-white)';
};

export const state = () => ({
  workspace: {},
  currentCategoryName: null,
  newVersion: false,
  currentScope: null,
  baseBorderRadiusPreview: null,
  primaryColorPreview: null,
});

type State = ReturnType<typeof state>;

const extractResourceIds = (order) => {
  if (order.items) {
    return order.items.flatMap(extractResourceIds);
  }

  return [order.id];
};

export const getters = <GetterTree<State, any>>{
  isChurned: state => state.workspace.plan === 'churned',
  /* Workspace alerts */
  alerts({ workspace }) {
    const alerts: string[] = [];
    const auth = useAuth();

    if (auth.user?.role === 'admin') {
      if (workspace.has_dummy_data) {
        alerts.push('dummyData');
      }

      if (workspace.not_configured_integrations?.length > 0) {
        alerts.push('notConfiguredIntegrations');
      }
    }

    return alerts;
  },
  /* Category Hierarchy */
  categoryHierarchy: state => [state.workspace?.category_hierarchy || []].flat(),
  categoryHierarchyWithoutSelected(state, { categoryHierarchy }) {
    return categoryHierarchy.filter(({ category_name: name }) => name !== state.currentCategoryName);
  },
  currentCategory: (state, { categoryHierarchy }) => categoryHierarchy?.find(({ category_name: name }) => name === state.currentCategoryName),
  currentCategoryNavigationType: (_, { currentCategory }) => currentCategory?.navigation_type,
  firstCategoryWithView(_, { categoryHierarchy }) {
    return categoryHierarchy.find(({ views }) => views && views.length > 0);
  },
  viewsOrder(_state, { currentCategory }) {
    return currentCategory?.views || [];
  },
  allVisibleViewIds(_, { allHiddenViewIds, allViewIds }) {
    return allViewIds.filter(viewId => !allHiddenViewIds.includes(viewId));
  },
  hiddenViewsOrder(_state, { currentCategory }) {
    return currentCategory?.hidden_views || [];
  },
  postInstallationSetupViewsOrder(_state, { currentCategory }) {
    return currentCategory?.post_installation_setup_views || [];
  },
  publicViewsOrder(_state, { currentCategory }) {
    return currentCategory?.public_views || [];
  },
  allWorkflowIds({ workspace }) {
    if (!workspace.category_hierarchy || workspace.category_hierarchy.length === 0) return [];

    const result = [];

    workspace.category_hierarchy.forEach((category) => {
      if (category.workflows) {
        category.workflows.forEach((order) => {
          result.push(...extractResourceIds(order));
        });
      }
    });

    return result;
  },
  allSheetIds({ workspace }) {
    if (!workspace.category_hierarchy || workspace.category_hierarchy.length === 0) return [];

    const result = [];

    workspace.category_hierarchy.forEach((category) => {
      if (category.sheets) {
        category.sheets.forEach((order) => {
          result.push(...extractResourceIds(order));
        });
      }
    });

    return uniq(result);
  },
  allSystemHiddenViewIds(_state, { allHiddenViewIds, allPostInstallationSetupViewIds }) {
    return allHiddenViewIds.concat(allPostInstallationSetupViewIds);
  },
  allHiddenViewIds({ workspace }) {
    if (!workspace.category_hierarchy || workspace.category_hierarchy.length === 0) return [];

    const result = [];

    workspace.category_hierarchy.forEach((category) => {
      if (category.hidden_views) {
        category.hidden_views.forEach((order) => {
          result.push(...extractResourceIds(order));
        });
      }
    });

    return result;
  },
  allPostInstallationSetupViewIds({ workspace }) {
    if (!workspace.category_hierarchy || workspace.category_hierarchy.length === 0) return [];

    const result = [];

    workspace.category_hierarchy.forEach((category) => {
      if (category.post_installation_setup_views) {
        category.post_installation_setup_views.forEach((order) => {
          result.push(...extractResourceIds(order));
        });
      }
    });

    return result;
  },
  allViewIds({ workspace }) {
    if (!workspace.category_hierarchy || workspace.category_hierarchy.length === 0) return [];

    const result = [];

    workspace.category_hierarchy.forEach((category) => {
      ['views', 'hidden_views', 'post_installation_setup_views', 'public_views'].forEach((key) => {
        if (category[key]) {
          category[key].forEach((order) => {
            result.push(...extractResourceIds(order));
          });
        }
      });
    });

    return result;
  },
  allPublicViewIds({ workspace }) {
    if (!workspace.category_hierarchy || workspace.category_hierarchy.length === 0) return [];

    const result = [];

    workspace.category_hierarchy.forEach((category) => {
      if (category.public_views) {
        category.public_views.forEach((order) => {
          result.push(...extractResourceIds(order));
        });
      }
    });

    return result;
  },
  allPublicViewsOrder(_, { categoryHierarchy }) {
    return categoryHierarchy.reduce((acc, category) => acc.concat(category.public_views || []), []);
  },
  /* Scope */
  defaultScope: () => getDefaultScope(),
  availableScopes: (_, { defaultScope }, { viewsMenu: { views } }, rootGetters) => {
    const auth = useAuth();
    if (!auth?.user) return [];

    const currentCategory = rootGetters['workspace/currentCategory'];
    const filteredViews = views.filter(view => view.category.id === currentCategory?.id);

    const userGroupScopes = auth.user.user_groups
      .filter(group => group.use_as_navigation_scope && filteredViews.some(view => viewBelongsToScope(view, { key: group.id }, auth.user)))
      .map(group => ({
        key: group.id,
        title: group.name,
        icon: `fa-regular fa-${group.icon}`,
      }));

    return [defaultScope].concat(userGroupScopes);
  },
  currentScope: (state, { availableScopes, defaultScope }) => ((availableScopes || []).map(scope => scope.key).includes(state.currentScope?.key) ? state.currentScope : defaultScope),
  visibleViewsInCurrentScope: (_, { viewsInCurrentScope, allVisibleViewIds }) => viewsInCurrentScope.filter(view => allVisibleViewIds.includes(view.id)),
  viewsInCurrentScope: (_, { currentScope }, { viewsMenu: { views } }) => viewsInScope(views, currentScope, useAuth().user),
  categoryHierarchyScoped(_, { categoryHierarchy, viewsInCurrentScope }) {
    const categoriesThatHaveViewsInThatScope = new Set();

    viewsInCurrentScope.forEach((view) => {
      if (!view.category) return;
      categoriesThatHaveViewsInThatScope.add(view.category.id);
    });

    return categoryHierarchy.filter(({ id }) => categoriesThatHaveViewsInThatScope.has(id)); // shows only categories that have views in that scope
  },
  viewsOrderScoped(state, { categoryHierarchyScoped }) {
    const category = categoryHierarchyScoped.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    return category ? category.views : [];
  },
  firstScopedViewId(_, { viewsOrderScoped, visibleViewsInCurrentScope }) {
    const viewIdsInScope = visibleViewsInCurrentScope.map(v => v.id);
    let firstView;

    const findFirstView = (viewsOrderScoped) => {
      if (firstView) return;

      if (viewsOrderScoped.items && viewsOrderScoped.items.length > 0) {
        viewsOrderScoped.items.forEach(findFirstView);
      } else if (viewIdsInScope.includes(viewsOrderScoped.id)) {
        firstView = viewsOrderScoped;
      }
    };

    viewsOrderScoped.forEach(findFirstView);

    return firstView?.id;
  },
  /* Workspace Workflows */
  workflowsOrder({ currentCategoryName }, { categoryHierarchy }) {
    if (!categoryHierarchy) return [];
    const category = categoryHierarchy.find(categoryNode => categoryNode.category_name === currentCategoryName);
    return (category ? category.workflows : []) || [];
  },
  firstWorkflowId(_, { workflowsOrder }) {
    let firstWorkflow;

    const findFirstWorkflowsOrder = (workflowOrder) => {
      if (firstWorkflow) return;

      if (workflowOrder.items && workflowOrder.items.length > 0) {
        workflowOrder.items.forEach(findFirstWorkflowsOrder);
      } else {
        firstWorkflow = workflowOrder;
      }
    };

    workflowsOrder.forEach(findFirstWorkflowsOrder);

    return firstWorkflow?.id;
  },
  firstSheetId(_, { sheetsOrder }) {
    let firstSheet;

    const findFirstSheet = (sheetsOrder) => {
      if (firstSheet) return;

      if (sheetsOrder.items && !_isEmpty(sheetsOrder.items)) {
        sheetsOrder.items.forEach(findFirstSheet);
      } else {
        firstSheet = sheetsOrder;
      }
    };

    sheetsOrder.forEach(findFirstSheet);

    return firstSheet?.id;
  },
  firstViewId(_, { viewsOrder, allVisibleViewIds }) {
    let firstView;

    const findFirstView = (viewsOrder) => {
      if (firstView) return;

      if (viewsOrder.items && viewsOrder.items.length > 0) {
        viewsOrder.items.forEach(findFirstView);
      } else if (allVisibleViewIds.includes(viewsOrder.id)) {
        firstView = viewsOrder;
      }
    };

    viewsOrder.forEach(findFirstView);

    return firstView?.id;
  },
  /* Sheets */
  sheetsOrder({ currentCategoryName }, { categoryHierarchy }) {
    if (!categoryHierarchy) return [];
    const category = categoryHierarchy.find(categoryNode => categoryNode.category_name === currentCategoryName);
    return (category ? category.sheets : []) || [];
  },
  timeZone: state => state.workspace.config?.time_zone || 'America/Sao_Paulo',
  locale: state => LANGUAGE_LOCALE_MAP[state.workspace.config?.language] || 'pt-BR',
  primaryColor: state => state.primaryColorPreview || state.workspace.config?.primary_color || THEME_COLORS.base.primary,
  secondaryColor: state => state.workspace.config?.secondary_color || THEME_COLORS.base.secondary,
  baseSpacing: state => state.workspace.config?.base_spacing || '4px',
  baseBorderRadius: state => state.baseBorderRadiusPreview || state.workspace.config?.base_border_radius || 'var(--z-r2)',
  primaryTextColor: state => computeContrastTextColor(state.workspace.config?.primary_color || THEME_COLORS.base.primary),
  secondaryTextColor: state => computeContrastTextColor(state.workspace.config?.secondary_color || THEME_COLORS.base.secondary),
  defaultTheme: state => state.workspace?.config?.default_theme || 'light',
  logoUrl: state => state.workspace.logo_image?.download_url?.logo,
  backgroundUrl: state => state.workspace.background_image?.download_url?.background,
  liveApp: state => state.workspace.live_app,
  hasDummyData: state => state.workspace.has_dummy_data,
  workspaceName: state => state.workspace.name,
  signupEnabled: state => state.workspace.signup_enabled,
  tenantSlug: state => state.workspace.slug,
};

export const mutations = <MutationTree<State>>{
  setWorkspace(state, workspace) {
    state.workspace = workspace;

    if (!state.currentCategoryName) {
      state.currentCategoryName = workspace.category_hierarchy?.[0]?.category_name;
    }
  },
  setBaseBorderRadiusPreview(state, value) {
    state.baseBorderRadiusPreview = value;
  },
  setPrimaryColorPreview(state, value) {
    state.primaryColorPreview = value;
  },
  updateViewsOrder(state, viewsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.views = viewsOrder;
  },
  updateHiddenViewsOrder(state, hiddenViewsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.hidden_views = hiddenViewsOrder;
  },
  updatePostInstallationSetupViewsOrder(state, postInstallationSetupViewsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.post_installation_setup_views = postInstallationSetupViewsOrder;
  },
  updatePublicViewsOrder(state, publicViewsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.public_views = publicViewsOrder;
  },
  updateWorkflowsOrder(state, workflowsOrder) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.workflows = workflowsOrder;
  },
  updateSheetsOrder(state, sheetsOrder) {
    state.workspace = {
      ...state.workspace,
      sheets_order: sheetsOrder,
    };
  },
  updateWorkspacePrimaryColor(state, primaryColor) {
    state.workspace.config = {
      ...state.workspace.config,
      primary_color: primaryColor,
    };
  },
  setCurrentCategoryName(state, categoryName) {
    state.currentCategoryName = categoryName;
  },
  setNewVersion(state, newVersion) {
    state.newVersion = newVersion;
  },
  setCurrentScope(state, scope) {
    state.currentScope = scope;
  },
  setDefaultScope(state) {
    state.currentScope = getDefaultScope();
  },
  updateCurrentCategoryNavigationType(state, navigationType) {
    const category = state.workspace.category_hierarchy.find(categoryNode => categoryNode.category_name === state.currentCategoryName);
    category.navigation_type = navigationType;
  },
};

export const actions = <ActionTree<State, any>>{
  firstViewIdFromViews({ getters: { viewsOrder } }, views) {
    if (viewsOrder.length === 0) return views[0]?.id;

    let firstView;

    const findFirstView = (viewsOrder) => {
      if (firstView) return;

      if (viewsOrder.items && viewsOrder.items.length > 0) {
        viewsOrder.items.forEach(findFirstView);
      } else if (views.find(view => view.id === viewsOrder.id && !view.archived_at)) {
        firstView = viewsOrder;
      }
    };

    viewsOrder.forEach(findFirstView);

    return firstView?.id;
  },
  async loadWorkspace(
    { commit, state, dispatch },
    params: {
      tenantSlug?: string;
      builder?: boolean;
      waitLoad?: boolean;
      isPublic?: boolean
      skipNextCall?: boolean;
    } = {},
  ) {
    if (params.skipNextCall) {
      skipNextLoadWorkspaceCall.value = true;
    } else if (skipNextLoadWorkspaceCall.value) {
      skipNextLoadWorkspaceCall.value = false;
      return null;
    }

    const nuxtApp = useNuxtApp();
    const { $api } = nuxtApp;

    const { waitLoad } = params;
    delete params.waitLoad;
    delete params.skipNextCall;

    const promise = params.isPublic ? $api.$get(`public/workspaces/${params.tenantSlug}`) : $api.$get('workspace', { params });

    // If workspace is already loaded, reload it without awaiting
    if (!isEmpty(state.workspace) && !waitLoad) {
      promise.then((data) => {
        commit('setWorkspace', data);

        if (params.builder) {
          dispatch('workflows/loadWorkflows', {}, { root: true });
          dispatch('viewOptions/fetchSheetsCache', {}, { root: true });
          dispatch('builderView/loadViews', {}, { root: true });
        }
      }).catch(handleWorkspaceLoadError.bind(nuxtApp));

      return null;
    }

    try {
      const data = await promise;
      commit('setWorkspace', data);

      if (params.builder) {
        await Promise.all([ // TODO: refactor these actions to centralize resources indexes in workspace
          dispatch('workflows/loadWorkflows', {}, { root: true }),
          dispatch('viewOptions/fetchSheetsCache', {}, { root: true }),
          dispatch('builderView/loadViews', {}, { root: true }),
        ]).catch(handleWorkspaceLoadError.bind(nuxtApp));
      }

      return data;
    } catch (error) {
      return handleWorkspaceLoadError.call(nuxtApp, error);
    }
  },
  async updateWorkspace(_, payload) {
    await useNuxtApp().$api.$patch('/workspace', { body: payload });
  },
  async updateViewsOrder({ commit, dispatch, state }, viewsOrder) {
    commit('updateViewsOrder', viewsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, views_order: viewsOrder });
  },
  async updateHiddenViewsOrder({ commit, dispatch, state }, hiddenViewsOrder) {
    commit('updateHiddenViewsOrder', hiddenViewsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, hidden_views_order: hiddenViewsOrder });
  },
  async updatePostInstallationSetupViewsOrder({ commit, dispatch, state }, postInstallationSetupViewsOrder) {
    commit('updatePostInstallationSetupViewsOrder', postInstallationSetupViewsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, post_installation_setup_views_order: postInstallationSetupViewsOrder });
  },
  async updatePublicViewsOrder({ commit, dispatch, state }, publicViewsOrder) {
    commit('updatePublicViewsOrder', publicViewsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, public_views_order: publicViewsOrder });
  },
  async updateNavigationType({ dispatch, state }, navigationType) {
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, navigation_type: navigationType });
  },
  async updateWorkflowsOrder({ commit, dispatch, state }, workflowsOrder) {
    commit('updateWorkflowsOrder', workflowsOrder);
    await dispatch('updateWorkspace', { app_category_name: state.currentCategoryName, workflows_order: workflowsOrder });
  },
  async updateSheetsOrder({ commit, dispatch, getters, state }, sheetsOrder) {
    const completeSheetsOrder = getters.categoryHierarchy.reduce((acc, category) => {
      if (category.category_name === state.currentCategoryName || !state.currentCategoryName) {
        return acc.concat(sheetsOrder);
      }

      return acc.concat(category.sheets);
    }, []);

    commit('updateSheetsOrder', completeSheetsOrder);
    await dispatch('updateWorkspace', { sheets_order: completeSheetsOrder });
  },
  async updateWorkspacePrimaryColor({ commit, dispatch, state }, primaryColor) {
    commit('updateWorkspacePrimaryColor', primaryColor);
    await dispatch('updateWorkspace', { config: { ...state.workspace.config, primary_color: primaryColor } });
  },
  async signUp({ commit }, { username, password, name, recaptchaToken, marketingEmailApproval, termsApproval, userFullName, userPhoneNumber }) {
    const { $api, $router } = useNuxtApp();
    const workspace = await $api.$post(
      '/workspace',
      {
        body: {
          username,
          password,
          name,
          recaptcha_token: recaptchaToken,
          marketing_email_approval: marketingEmailApproval,
          terms_approval: termsApproval,
          user_full_name: userFullName,
          user_phone_number: userPhoneNumber,
        },
        timeout: 30000,
      },
    );

    commit('setWorkspace', workspace);
    window.analytics.track('TenantCreated', { username, currentPath: $router.currentRoute.value.path });
    return workspace;
  },
  setCategoryBySheetId({ commit, getters }, sheetId) {
    const categoryName = getters
      .categoryHierarchy
      .find(({ sheets }) => isIdPresentInRecursiveList(sheets, sheetId))?.category_name;

    if (categoryName) {
      commit('setCurrentCategoryName', categoryName);
    }
  },
  setCategoryByWorkflowId({ commit, getters }, workflowId) {
    const categoryName = getters
      .categoryHierarchy
      .find(({ workflows }) => isIdPresentInRecursiveList(workflows, workflowId))?.category_name;

    if (categoryName) {
      commit('setCurrentCategoryName', categoryName);
    }
  },
  setCategoryByViewId({ commit, getters }, viewId) {
    const categoryName = getters
      .categoryHierarchy
      .find(
        ({ views, post_installation_setup_views: setupViews, hidden_views: hiddenViews }) => isIdPresentInRecursiveList(views, viewId)
          || isIdPresentInRecursiveList(setupViews, viewId)
          || isIdPresentInRecursiveList(hiddenViews, viewId),
      )?.category_name;

    if (categoryName) {
      commit('setCurrentCategoryName', categoryName);
    }
  },
  updateCurrentScope({ commit, getters }, { scope }) {
    const scopeToSet = getters.availableScopes.find(s => s.key === scope.key) || getters.defaultScope;

    commit('setCurrentScope', scopeToSet);
  },
  async importWorkspace({ dispatch }, ymlContent) {
    await useNuxtApp().$api.$post('/workspace/import', { body: { yml_content: ymlContent } });
    return dispatch('loadWorkspace');
  },
};

export const namespaced = true;
