<template>
  <v-row
    :class="`deck-exodia-footer deck-exodia-footer--${size} text-truncate`"
    no-gutters
  >
    <v-col class="flex-grow-1 text-truncate deck-exodia-footer__content">
      <slot name="footer-content">
        <span :class="`deck-exodia-footer__content-text deck-exodia-footer__content-text--${size}`">
          {{ footerContent }}
        </span>
      </slot>
    </v-col>
    <v-col
      v-if="hasBtns"
      ref="exodiaButtons"
      class="deck-exodia-footer__buttons"
    >
      <v-row
        no-gutters
        class="flex-nowrap text-truncate"
      >
        <v-col v-if="secondaryBtnText || secondaryBtnIcon">
          <deck-button
            is-ready
            color="primary"
            kind="ghost"
            :size="buttonSize"
            :disabled="secondaryBtnDisabled"
            @click="$emit('secondaryBtnClick')"
          >
            <v-icon
              v-if="secondaryBtnIcon"
              data-testid="secondary-button-icon"
              :class="secondaryBtnText ? 'mr-2' : ''"
            >
              {{ secondaryBtnIcon }}
            </v-icon>
            {{ secondaryBtnText }}
          </deck-button>
        </v-col>

        <v-col
          v-if="primaryBtnText || primaryBtnIcon"
          class="ml-2"
        >
          <deck-button
            is-ready
            color="primary"
            :size="buttonSize"
            :disabled="primaryBtnDisabled"
            @click="$emit('primaryBtnClick')"
          >
            <v-icon
              v-if="primaryBtnIcon"
              data-testid="primary-button-icon"
              :class="primaryBtnText ? 'mr-2' : ''"
            >
              {{ primaryBtnIcon }}
            </v-icon>
            {{ primaryBtnText }}
          </deck-button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  setRootCssProp,
  getRootCssProp,
  clearRootCssProp,
} from '~/assets/javascript/utils/dom';

export default {
  name: 'DeckExodiaFooter',
  props: {
    size: { type: String, required: true },
    footerContent: { type: String, default: null },
    primaryBtnText: { type: String, default: null },
    primaryBtnIcon: { type: String, default: null },
    secondaryBtnText: { type: String, default: null },
    secondaryBtnIcon: { type: String, default: null },
    primaryBtnDisabled: { type: Boolean, default: false },
    secondaryBtnDisabled: { type: Boolean, default: false },
  },
  emits: ['secondaryBtnClick', 'primaryBtnClick'],
  data() {
    return {
      // eslint-disable-next-line vue/no-unused-properties
      buttonBottomDistance: null, // Distance of the top of the buttons to the bottom of the viewport
      debouncedSetIntercomOffset: _debounce(this.setIntercomOffset, 150),
    };
  },
  computed: {
    hasBtns() {
      return this.primaryBtnText
        || this.primaryBtnIcon
        || this.secondaryBtnText
        || this.secondaryBtnIcon;
    },
    buttonSize() {
      return this.size === 'small' ? 'small' : 'default';
    },
  },
  mounted() {
    const allButtonContainers = Array.from(document.querySelectorAll('.deck-exodia-footer__buttons'));

    // If there are more than one button container, we don't want to mess with the intercom offset
    if (allButtonContainers.length === 1) {
      this.createIntersectionObserver();
      window.addEventListener('resize', this.debouncedSetIntercomOffset);
    }
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }

    window.removeEventListener('resize', this.debouncedSetIntercomOffset);
    clearRootCssProp('intercom-offset-element-top-to-viewport-end');
  },
  methods: {
    createIntersectionObserver() {
      const observerOptions = {
        root: null, // Use the viewport as the root
        threshold: 0.7, // Execute callback when 70% of the element is visible
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
          // Element is now visible, set intercom offset
            this.debouncedSetIntercomOffset();
          } else {
          // Element is not visible, reset intercom offset
            clearRootCssProp('intercom-offset-element-top-to-viewport-end');
          }
        });
      }, observerOptions);

      if (this.$refs.exodiaButtons) {
        this.observer.observe(this.$refs.exodiaButtons.$el);
      }
    },
    setIntercomOffset() {
      if (!this.$refs.exodiaButtons) return;

      const { bottom, right } = this.$refs.exodiaButtons.$el.getBoundingClientRect();

      const exodiaButtonsBottomOffset = window.innerHeight - bottom;
      const exodiaButtonsRightOffset = window.innerWidth - right;
      const intercomButtonHeight = parseInt(getRootCssProp('intercom-button-height').replace('px', ''), 10);
      const intercomButtonWidth = parseInt(getRootCssProp('intercom-button-width').replace('px', ''), 10);
      const intercomButtonBottomOffset = parseInt(getRootCssProp('intercom-button-distance-bottom-viewport').replace('px', ''), 10);
      const intercomButtonRightOffset = parseInt(getRootCssProp('intercom-button-distance-right-viewport').replace('px', ''), 10);

      const safetyMargin = 10;

      // The following diagram represents the viewport and the button
      // to better explain the next calculation:
      //
      // Exodia button
      //
      //   ---------------------  - -> exodiaButtonsHeight
      //   |      button       |  |
      //   |                   |  |
      //   ---------------------  -                     - -> exodiaButtonsBottomOffset
      //                                                |
      //   _____________________ _> end of viewport     -
      //
      // ************************************************************
      //
      // Intercom:
      //
      //   ---------------------  - -> intercomButtonHeight
      //   |    intercom btn   |  |
      //   |                   |  |
      //   ---------------------  -                     - -> intercomButtonBottomOffset
      //                                                |
      //   _____________________ _> end of viewport     -

      const offsetIsPositive = exodiaButtonsBottomOffset > 0;
      const offsetIsWithinIntercomXArea = exodiaButtonsBottomOffset < intercomButtonHeight + intercomButtonBottomOffset + safetyMargin;
      const offsetIsWithinIntercomYArea = exodiaButtonsRightOffset < intercomButtonWidth + intercomButtonRightOffset + safetyMargin;

      const buttonIsWithinIntercomArea = offsetIsPositive && offsetIsWithinIntercomXArea && offsetIsWithinIntercomYArea;

      if (buttonIsWithinIntercomArea) {
        // The real offset should add 'intercomButtonBottomOffset' because we subtracted it in _intercom.scss
        // Plus we add a safety margin just to be sure
        setRootCssProp('intercom-offset-element-top-to-viewport-end', `${exodiaButtonsBottomOffset + intercomButtonBottomOffset + safetyMargin}px`);
      } else {
        clearRootCssProp('intercom-offset-element-top-to-viewport-end');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-exodia-footer {
  border-radius: 0 !important;
  flex-wrap: wrap-reverse;
  justify-content: end;
  min-height: 36px;
  padding: var(--z-s2);

  &--small{
    min-height: 32px;
  }
}

.deck-exodia-footer__content{
  min-width: 176px;
}

.deck-exodia-footer__content-text {
  font-size: 16px !important;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.4) !important;
  margin-top: 4px;

  &--small{
    font-size: 12px !important;
    line-height: 32px;
  }
}

.deck-exodia-footer__buttons{
  flex-grow: 0;
}

</style>
