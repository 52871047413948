<template>
  <div class="h-full">
    <p
      v-if="fetchData.viewLoadError"
      class="ma-8 pa-6 rounded bg-white"
    >
      {{ fetchData.viewLoadError }}
    </p>

    <TheLandscape
      v-else
      :record-id="showView && fetchData.newRecordId"
      :view="showView ? view : null"
      :filter-main-content="showView"
      :outlined="false"
      :loading="!showView"
      page-state-component-key="single_item"
      class="h-full"
      @submitted="redirectAfterCreate"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters, mapState, mapActions } from '~/assets/javascript/modules/vuex';
import { VIEW_TYPES } from '~/assets/javascript/constants';

export default {
  name: 'MemberViewNewRecord',
  components: {
    TheLandscape: defineAsyncComponent(() => import('~/components/views/the-landscape')),
  },
  async setup() {
    definePageMeta({
      layout: 'member',
      middleware: ['enforce-app-dependencies'],
    });

    const vuex = {
      ...mapState('view', ['view']),
      // eslint-disable-next-line vue/no-unused-properties
      ...mapGetters('workspace', ['viewCategories']),
      // eslint-disable-next-line vue/no-unused-properties
      ...mapGetters('records', ['hasChangesInDraftRecords']),
      // eslint-disable-next-line vue/no-unused-properties
      ...mapActions('records', ['buildNewRecord']),
    };

    useConfirmLeave({
      warnRouteLeave() {
        return vuex.hasChangesInDraftRecords.value;
      },
      warnPageClose() {
        return vuex.hasChangesInDraftRecords.value;
      },
    });

    const { view, showView } = useView();

    const { data: fetchData } = await useLazyAsyncData(async () => {
      const { viewLoadError } = await useViewFetch();

      useTrackAppUsage('new');

      const newRecordId = await vuex.buildNewRecord({ view: view.value });

      return {
        newRecordId,
        viewLoadError,
      };
    }, {
      default() {
        return {
          newRecordId: null,
          viewLoadError: null,
        };
      },
    });

    return {
      fetchData,
      view,
      showView,
      ...vuex,
    };
  },
  methods: {
    redirectAfterCreate() {
      // we should manually redirect to the page index if there are no submit redirect configs
      const hasSubmitButtonConfig = Boolean(VIEW_TYPES[this.view.page_type]?.components?.find(({ key }) => key === 'single_item')?.main_section?.exodiaComponents?.submit_button);

      if (hasSubmitButtonConfig) return;

      this.$router.push(this.localePath({
        name: 't-tenantSlug-m-viewId',
        params: { tenantSlug: this.$route.params.tenantSlug, viewId: this.$route.params.viewId },
      }));
    },
  },
};
</script>
