import { v4 as uuidv4 } from 'uuid';

export default class ActionButtonInfoComponentOptionBuilder {
  actionButtonInfoComponent: any;

  static build(infoComponent) {
    return new ActionButtonInfoComponentOptionBuilder(infoComponent).build();
  }

  constructor(infoComponent) {
    this.actionButtonInfoComponent = infoComponent;
  }

  build() {
    const actionButtonInfoComponentId = this.actionButtonInfoComponent.id || uuidv4();

    const actionButtonInfoComponentOption = {
      ...this.actionButtonInfoComponent,
      id: actionButtonInfoComponentId,
      title: this.actionButtonInfoComponent.label,
      titleTypography: 'text-body-2',
      icon: 'fa-regular fa-filter',
    };

    return actionButtonInfoComponentOption;
  }
}
