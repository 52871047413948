import type { NuxtApp } from '#app';
import { SetupCredentials } from './credentials';
import { SetupEmailValidation } from './email-validation';
import { SetupMagicLink } from './magic-link';
import { SetupSaml } from './saml';

export const LoginSetup = (nuxt: NuxtApp) => {
  SetupCredentials(nuxt);
  SetupSaml(nuxt);
  SetupMagicLink(nuxt);
  SetupEmailValidation(nuxt);
};
