<template>
  <div
    class="studio-data"
  >
    <div id="builderArea">
      <ResizeHandler
        resource-key="data"
        slot-class="builder-navigation builder-navigation--left"
        left
        :default-width="256"
      >
        <StudioSidebar
          resource="sheet"
          @data-changed="updateData"
          @loading="isDataLoading = $event"
        />
      </ResizeHandler>
      <div class="studio-data__content">
        <DataTable
          id="mainTable"
          v-model="selected"
          allow-field-group-change
          allow-field-reorder
          mode="admin"
          class="h-full flex-fluid"
          :fields="view.fields"
          :can-edit="fullPower"
          :can-create="fullPower"
          :can-delete="fullPower"
          :with-border="false"
          :skeleton="showSkeleton"
          @data-changed="refreshView"
          @single-field-selected="singleFieldSelected = $event"
          @single-field-not-selected="singleFieldSelected = {}"
          @loading="pauseViewDataRefresh"
          @toggle-history="displayHistory = !displayHistory"
        />
      </div>
      <ResizeHandler
        v-if="displayHistory"
        v-disable-click-outside-data-table
        slot-class="builder-navigation builder-navigation--right"
        resource-key="data"
        right
        :default-width="256"
      >
        <DataHistory
          :field="singleFieldSelected.field"
          :record-id="singleFieldSelected.record && singleFieldSelected.record.id"
          @close="displayHistory = false"
        />
      </ResizeHandler>
    </div>
  </div>
</template>

<script lang="ts">
import { mapMutations, mapActions, mapState } from '~/assets/javascript/modules/vuex';
import StudioSidebar from '~/components/studio/Sidebar';
import viewChannelMixin from '~/mixins/viewChannelMixin';
import DataHistory from '~/components/studio/data-history';
import ResizeHandler from '~/components/resize-handler';
import devPerformanceTrackingMixin from '~/mixins/devPerformanceTrackingMixin';

export default defineComponent({
  name: 'PageStudioDataId',
  components: {
    DataHistory,
    DataTable: defineAsyncComponent(() => import('~/components/data-table')),
    ResizeHandler,
    StudioSidebar,
  },
  mixins: [devPerformanceTrackingMixin, viewChannelMixin({ storeLoadViewPath: 'builderView/loadBuilderView' })],
  setup() {
    definePageMeta({
      layout: 'studio',
      key: 'data',
      middleware: [
        'auth',
        'refresh-user',
        'is-admin',
      ],
    });

    const vuex = {
      ...mapState('builderView', ['view']),
      ...mapMutations('dialog', ['openDialog']),
      ...mapMutations('viewOptions', ['setIsPreviewMode']),
      ...mapMutations('table', ['initTable']),
      ...mapActions('builderView', ['loadBuilderView']),
      ...mapActions('workspace', ['setCategoryBySheetId']),
    };

    buildHead({ title: computed(() => vuex.view.value.sheet?.name) });

    return vuex;
  },
  data() {
    return {
      isDataLoading: false,
      pendingFetch: false,
      selected: new Set(),
      matchedUsersData: null,
      singleFieldSelected: {},
      displayHistory: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/no-unused-properties
    viewChannelMixin__view() {
      return this.view;
    },
    fullPower() {
      return true;
    },
    showSkeleton() {
      return this.pendingFetch || _isEmpty(this.view) || this.view.sheet_id !== this.$route.params.id;
    },
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.fetchData();
      },
      immediate: true,
    },
  },
  methods: {
    async fetchData() {
      this.pendingFetch = true;
      this.setIsPreviewMode(false);
      await this.updateData();
      await this.integrationModal();
      this.setCategoryBySheetId(this.$route.params.id);
      this.pendingFetch = false;
    },
    refreshView(payload = {}) {
      if (!payload.recordId) {
        this.viewChannelMixin__throttledLoadView();
      }
    },
    pauseViewDataRefresh(value) {
      this.isDataLoading = value;
      this.viewChannelMixin__isPaused = value;
    },
    async updateData() {
      this.isDataLoading = true;

      const { matched_dialog: matchedDialog } = this.$route.query;
      const params = { fetch_default_view: true };

      if (matchedDialog) {
        params.matched_users_data = true;
      }

      const sheet = await this.$api.$get(`/sheets/${this.$route.params.id}`, { params });
      await this.loadBuilderView({ viewId: sheet.default_view.id });

      const sortCache = _get(
        this.$store.state.viewOptions.sortBy,
        this.view.id,
      );

      if (!sortCache) {
        this.$store.commit('viewOptions/saveSorting', {
          viewId: this.view.id,
          sortBy: [],
          sortDesc: [],
        });
      }

      this.matchedUsersData = sheet.matched_users_data;
      this.isDataLoading = false;

      this.initTable();
    },
    async integrationModal() {
      if (this.matchedUsersData) {
        const {
          matched_count: matchedUsersCount,
          unmatched_count: unmatchedUsersCount,
        } = this.matchedUsersData;

        if (!matchedUsersCount || !unmatchedUsersCount) return;

        this.openDialog({
          component: 'DialogIntegrationMatches',
          attrs: {
            matchedUsersCount: parseInt(matchedUsersCount, 10),
            unmatchedUsersCount: parseInt(unmatchedUsersCount, 10),
          },
        });
      }
    },
  },
});
</script>
<style lang="scss">
.studio-data {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#builderArea {
  flex: 1 1 0;
}

.studio-data__content {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-width: 0;
  padding-bottom: var(--z-s4);
  position: relative;
}

.studio-data__history {
  border-right: thin solid var(--z-theme-background-secondary);
  border-left: thin solid var(--z-theme-background-secondary);
  background-color: white;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
