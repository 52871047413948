import validate from "/codebuild/output/src3963973704/src/zazos-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45reset_45magic_45link_45global from "/codebuild/output/src3963973704/src/zazos-web/middleware/auth-reset-magic-link.global.ts";
import save_45recent_45navigation_45global from "/codebuild/output/src3963973704/src/zazos-web/middleware/save-recent-navigation.global.js";
import manifest_45route_45rule from "/codebuild/output/src3963973704/src/zazos-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45reset_45magic_45link_45global,
  save_45recent_45navigation_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-fetch-user": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/auth-fetch-user.ts"),
  "auth-redirect": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/auth-redirect.ts"),
  "enforce-app-dependencies": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/enforce-app-dependencies.ts"),
  "is-admin": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/is-admin.ts"),
  "product-fruits": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/product-fruits.js"),
  "refresh-user": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/refresh-user.ts"),
  "slug-redirect": () => import("/codebuild/output/src3963973704/src/zazos-web/middleware/slug-redirect.js"),
  auth: () => import("/codebuild/output/src3963973704/src/zazos-web/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs")
}