<template>
  <div>
    <DeckInputLabel
      v-if="label"
      :for="String($.uid)"
    >
      {{ label }}
    </DeckInputLabel>

    <v-text-field
      v-if="editable || !displayName"
      :id="String($.uid)"
      ref="input"
      data-testid="deckDateInput"
      class="deck-date-input__input"
      :class="{
        'deck-date-input__input--fixed-height': !dense,
      }"
      :type="timeEnabled ? 'datetime-local':'date'"
      :model-value="modelValue"
      :hint="hint"
      :persistent-hint="persistentHint"
      :hide-details="hideDetails"
      :variant="outlined ? 'outlined' : 'filled'"
      :disabled="!editable"
      :density="dense ? 'compact' : 'default'"
      :rules="rules"
      max="9999-12-31"
      aria-valuemax="9999-12-31"
      v-bind="$attrs"
    />

    <v-input
      v-else
      :id="String($.uid)"
      ref="input"
      data-testid="deckDateInput"
      class="deck-date-input__input deck-date-input__input--readonly"
      :class="{
        'deck-date-input__input--fixed-height': !dense,
      }"
      :model-value="modelValue"
      :hint="hint"
      :persistent-hint="persistentHint"
      :hide-details="hideDetails"
      :variant="outlined ? 'outlined' : 'filled'"
      :disabled="!editable"
      :density="dense ? 'compact' : 'default'"
      :rules="rules"
      max="9999-12-31"
      aria-valuemax="9999-12-31"
      v-bind="$attrs"
    >
      <div class="deck-date-input__input-display-name">{{ displayName || defaultDateFormat }}</div>
    </v-input>
  </div>
</template>

<script>
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from '~/assets/javascript/constants';

export default {
  name: 'DeckDateInput',
  components: {
    DeckInputLabel: defineAsyncComponent(() => import('~/deck/inputs/_input-label')),
  },
  props: {
    dense: { type: Boolean, default: false },
    displayName: { type: String, default: null },
    editable: { type: Boolean, default: true },
    hideDetails: { type: [Boolean, String], default: 'auto' },
    hint: { type: String, default: null },
    label: { type: String, default: null },
    outlined: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    timeEnabled: { type: Boolean, default: false },
    modelValue: { type: String, default: null },
  },
  computed: {

    defaultDateFormat() {
      return (this.timeEnabled ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT).toLowerCase();
    },
    // eslint-disable-next-line vue/no-unused-properties
    showInput() {
      return this.editable || !this.displayName;
    },
  },
  methods: {
    // eslint-disable-next-line vue/no-unused-properties
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
// TODO: Double check what are the use cases outside the Workspace.
// I defined consistent styles only directly on InfoComponentFieldValueInput and
// not here for safety due to not understanding all use cases right now.

.deck-date-input__input {
  border-radius: var(--z-border-radius-inner-base) !important;

  .v-field__outline {
    &::before, &::after {
      border: none !important;
    }
  }

  .v-field__overlay {
    opacity: 0 !important;
  }

  .v-field__input::-webkit-calendar-picker-indicator {
    padding: 0;
  }
}

.deck-date-input__input--readonly {
  .deck-date-input__input-display-name {
    align-items: center;
    display: grid;
    width: 100%;
  }
}

.deck-date-input__input--fixed-height {
  .v-field__field {
    --v-field-padding-start: 12px;
    --v-field-padding-end: 12px;
  }
}
</style>
