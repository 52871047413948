// Feature flags are stored in localStorage and are available in the store
// To toggle feature, open browser with the following query param:
// Activate: ?feature_yourFeatureName=true
// Deactivate: ?feature_yourFeatureName=false

const INTERNAL_FEATURES = [
  'exportCategoryAsAIContext',
  'syncTriggerEditable',
];

export const state = () => ({
  features: {},
});

export const mutations = {
  toggleFeature(state, { flag, value }) {
    state.features = {
      ...state.features,
      [flag]: value,
    };
  },
  initializeFeatures(state, payload) {
    state.features = payload;
  },
};

export const getters = {
  isFeatureEnabled: state => flag => Boolean(state.features[flag]),
};

export const actions = {
  fetchFeatures({ commit }) {
    const features = JSON.parse(localStorage?.getItem('features') || '{}');
    const $auth = useAuth();

    if ($auth.user?.username?.includes('@zazos.com')) {
      INTERNAL_FEATURES.forEach((feature) => {
        features[feature] = true;
      });
    }

    commit('initializeFeatures', features);
  },
  toggleFeature({ commit, state }, { flag, value }) {
    commit('toggleFeature', { flag, value });
    localStorage?.setItem('features', JSON.stringify({ ...state.features, [flag]: value }));
  },
};

export const namespaced = true;
