export * from './user-group-scope';
export * from './calendar';
export * from './generate-navigation-items';

// Helper function to search for the view recursively
const findViewPath = (view, targetId, path = []) => {
  // Base case

  if (view.id === targetId) {
    return path;
  }

  // If the current view is a group, recursively search for the target view in its items
  if (view.items) {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of view.items) {
      const result = findViewPath(item, targetId, [...path, view.group_name]);

      if (result) return result;
    }
  }

  return null;
};

export const groupsPathToView = (views, viewId) => {
  // views must be like category.views, not a list of views.

  // If a view is inside Finance category and under the Budget group, under My Stuff group, the path will be ['Budget', 'My Stuff']

  // Iterate through each view and call the helper function to find the path
  // eslint-disable-next-line no-restricted-syntax
  for (const view of views) {
    const path = findViewPath(view, viewId);

    if (path) return path;
  }

  // View not found
  return null;
};

const categoryGroupsPathToView = (category, viewId) => groupsPathToView(category.views || [], viewId)
  || groupsPathToView(category.hidden_views || [], viewId)
  || groupsPathToView(category.post_installation_setup_views || [], viewId)
  || groupsPathToView(category.public_views || [], viewId);

export const enrichViewData = (views, categories) => {
  if (categories.length === 0) return views;

  const enrichedViewData = [];
  const viewsEnriched = {};

  categories.forEach((category) => {
    [...views.filter(v => !viewsEnriched[v.id])].forEach((view) => {
      const path = categoryGroupsPathToView(category, view.id); // tests all categories until it finds a valid path to the view

      if (path) {
        enrichedViewData.push({
          ...view,
          groupsPath: path,
          category,
        });

        viewsEnriched[view.id] = true;
      }
    });
  });

  return enrichedViewData;
};

const populateSubviewRecordIds = (subviews) => {
  (subviews || []).forEach((subview) => {
    subview.recordIds = subview.records?.map(({ id }) => id) || [];
    populateSubviewRecordIds(subview.subviews);
  });
};

export const extractViewRecords = ({ records, ...view }) => {
  view.recordIds = records?.map(({ id }) => id) || [];
  const viewRecords = records;

  delete view.records;

  populateSubviewRecordIds(view.subviews);
  return { records: viewRecords, view };
};
