import ActionButtonInfoComponentFromOptionBuilder from './action-button';
import ChartInfoComponentFromOptionBuilder from './chart';
import FieldInfoComponentFromOptionBuilder from './field';
import FilterBarInfoComponentFromOptionBuilder from './filter-bar';
import GroupInfoComponentFromOptionBuilder from './group';
import MarkdownInfoComponentFromOptionBuilder from './markdown';
import SubpageInfoComponentFromOptionBuilder from './subpage';

const infoComponentFromOptionBuilders = {
  'action-button': ActionButtonInfoComponentFromOptionBuilder,
  chart: ChartInfoComponentFromOptionBuilder,
  field: FieldInfoComponentFromOptionBuilder,
  filter_bar: FilterBarInfoComponentFromOptionBuilder,
  group: GroupInfoComponentFromOptionBuilder,
  markdown: MarkdownInfoComponentFromOptionBuilder,
  subpage: SubpageInfoComponentFromOptionBuilder,
};

export default class InfoComponentFromOptionBuilder {
  static build(infoComponent) {
    return new InfoComponentFromOptionBuilder(infoComponent).build();
  }

  constructor(infoComponentOption) {
    this.builder = new infoComponentFromOptionBuilders[infoComponentOption.type](infoComponentOption);
  }

  build() {
    return this.builder.build();
  }
}
