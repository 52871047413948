import { generatePlugin } from '~/assets/javascript/modules/auth';
import type { NuxtApp } from '#app';
import type { Module as CredentialsModule } from '~/assets/javascript/modules/auth/login/credentials/index';
import type { Module as EmailValidationModule } from '~/assets/javascript/modules/auth/login/email-validation/index';
import type { Module as MagicLinkModule } from '~/assets/javascript/modules/auth/login/magic-link/index';
import type { Module as ResetPasswordModule } from '~/assets/javascript/modules/auth/reset-password/index';
import type { Module as SAMLModule } from '~/assets/javascript/modules/auth/login/saml/index';
import type { Module as WorkspaceModule } from '~/assets/javascript/modules/auth/workspace/index';

declare module 'vue' {
  interface ComponentCustomProperties {
    $auth: NuxtApp['$auth'] & {
      credentialsModule: CredentialsModule;
      emailValidationModule: EmailValidationModule;
      logoutUser: () => void;
      magicLinkModule: MagicLinkModule;
      onRedirect: (callback: () => void) => void;
      resetPasswordModule: ResetPasswordModule;
      samlModule: SAMLModule;
      workspaceModule: WorkspaceModule;
    };
  }
}

export default defineNuxtPlugin({
  name: 'auth',
  dependsOn: ['store', 'recaptcha', 'error-rescue'],
  setup(nuxtApp) {
    return generatePlugin(nuxtApp);
  },
});
