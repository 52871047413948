<template>
  <NuxtLoadingIndicator
    color="rgb(var(--v-theme-primary))"
    error-color="rgb(var(--v-theme-error))"
  />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const { $store } = useNuxtApp();
const zazos = useZazos();

zazos.initializeTheme();
const zazosThemeClass = computed(() => `zazos-theme zazos-theme--${zazos.theme.value || 'light'}`);

const classes = computed(() => {
  const value = [zazosThemeClass.value];

  if ($store.state.environment.inIframe) {
    value.push('in-iframe');
  }

  return value.join(' ');
});

const zazosStyles = computed(() => ({
  '--z-theme-primary': $store.getters['workspace/primaryColor'],
  '--z-theme-primary-text': $store.getters['workspace/primaryTextColor'],
  '--z-theme-secondary': $store.getters['workspace/secondaryColor'],
  '--z-theme-secondary-text': $store.getters['workspace/secondaryTextColor'],
  '--z-space-base': $store.getters['workspace/baseSpacing'],
  '--z-border-radius-base': $store.getters['workspace/baseBorderRadius'],
  '--z-background-image': $store.getters['workspace/backgroundUrl'] ? `url(${$store.getters['workspace/backgroundUrl']})` : 'none',
}));

useHead({
  htmlAttrs: {
    class: classes,
    style: zazosStyles,
  },
});
</script>
