export class Module {
  constructor(nuxt) {
    this.$auth = nuxt.$auth;
    this.$recaptcha = nuxt.$recaptcha;
    this.store = nuxt.$store;
    this.$localePath = nuxt.$localePath;
    this.$router = nuxt.$router;
    this.$store = nuxt.$store;
  }

  get route() {
    return this.$router.currentRoute.value;
  }

  async create({ companyName, username, password, marketingEmailApproval, termsApproval, userFullName, userPhoneNumber }) {
    await this.$recaptcha.fetchToken(recaptchaToken => this.store.dispatch('workspace/signUp', {
      name: companyName,
      username,
      password,
      recaptchaToken,
      marketingEmailApproval,
      termsApproval,
      userFullName,
      userPhoneNumber,
    }));
  }
}

export const WorkspaceSetup = (nuxt) => {
  nuxt.$auth.workspaceModule = new Module(nuxt);
};
