<template>
  <div>
    <deck-label
      v-if="label && !floatingLabel"
      :input-ref="inputId"
      :text="label"
    />

    <v-text-field-money
      ref="input"
      v-model="currentValueState"
      v-test-id="'deckNumberInput'"
      class="deck-number-input__input"
      :class="{
        'deck-number-input__input--outlined': outlined,
        'deck-number-input__input--floating-label': floatingLabel,
        'deck-number-input__input--editable': editable,
        'deck-number-input__input--dense': dense,
      }"
      :properties="inputProperties"
      :options="inputOptions"
      :style="height ? 'height: ' + height + 'px' : ''"
    />
  </div>
</template>
<script>
import { floatifyNumber, integerifyNumber } from '~/assets/javascript/utils';

export default {
  name: 'DeckNumberInput',
  props: {
    dense: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    floatingLabel: { type: Boolean, default: false },
    height: { type: Number, default: undefined },
    hideDetails: { type: [Boolean, String], default: true },
    hint: { type: String, default: undefined },
    label: { type: String, default: null },
    locale: { type: String, default: 'pt-BR' },
    outlined: { type: Boolean, default: false },
    persistentHint: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    precision: { type: Number, default: 0 },
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    modelValue: { type: Number, default: null },
    variant: { type: String, default: 'filled' },
  },
  emits: ['blur', 'focus', 'update:modelValue'],
  data() {
    return {
      currentValueState: null,

      inputId: this.label && !this.floatingLabel ? String(this.$.uid) : undefined, // create an id for the input to use with custom label
    };
  },
  computed: {
    formattedValue() {
      const valueAsInteger = integerifyNumber(this.currentValueState, this.precision);

      return _isFinite(valueAsInteger) ? valueAsInteger : null;
    },
    inputProperties() {
      return {
        backgroundColor: undefined,
        density: this.dense ? 'compact' : 'default',
        disabled: !this.editable,
        variant: this.outlined ? 'outlined' : this.variant,
        hideDetails: this.hideDetails,
        hint: this.hint,
        id: this.inputId,
        placeholder: this.placeholder,
        persistentPlaceholder: true,
        persistentHint: this.persistentHint,
        prefix: this.prefix,
        rules: this.rules,
        style: this.height ? `height: ${this.height}px` : undefined,
        suffix: this.suffix,
        onBlur: event => this.$emit('blur', event),
        onFocus: event => this.$emit('focus', event),
        'onUpdate:modelValue': () => this.$emit('update:modelValue', this.formattedValue),
      };
    },
    inputOptions() {
      return {
        locale: this.locale,
        precision: this.precision,
        alphanumeric: true,
        allowNegative: true,
      };
    },
    placeholder() {
      if (this.label && this.floatingLabel) return this.label;

      if (this.precision > 0) {
        const separator = this.locale === 'pt-BR' ? ',' : '.';
        return `0${separator}${'0'.repeat(this.precision - 1)}`;
      }

      return '0';
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.currentValueState = !_isNil(value) ? floatifyNumber(value, this.precision) : null;
      },
    },
  },
};
</script>

<style lang="scss">
.deck-number-input__input {
  --deck-text-field-font-size: var(--z-font-size-medium);

  width: 100%;

  .v-field {
    font-size: var(--deck-text-field-font-size) !important;
  }

  .v-field__field {
    input::placeholder {
      color: rgba(0, 0, 0, 0.2) !important;
      opacity: 1;
    }
  }

  .v-field__overlay {
    opacity: 0 !important;
  }

  .v-field__outline {
    &::before, &::after {
      border: none !important;
    }
  }

  .v-text-field__prefix,
  .v-text-field__suffix {
    color: var(--z-text-color) !important;
  }
}

.deck-number-input__input--outlined {
  --deck-number-input-background-color: var(--z-input-background-color-read-only);
  --deck-number-input-border-color: var(--z-input-border-color-highlight);
  --deck-number-input-border-color-highlight: var(--z-input-border-color-read-only);

  .v-input__details {
    padding: 0 !important;
  }

  .v-input__slot.v-input__slot {
    border-style: solid;
    border-radius: var(--z-border-radius-inner-base);
    border-width: var(--z-input-border-width);
    border-color: var(--deck-number-input-border-color);
    background-color: var(--deck-number-input-background-color) !important;
    color: var(--z-color-text) !important;
    transition: border-color 200ms ease;
  }

  &.deck-number-input__input--editable {
    --deck-number-input-background-color: var(--z-input-background-color);
    --deck-number-input-border-color: var(--z-input-border-color);
    --deck-number-input-border-color-highlight: var(--z-input-border-color-highlight);
  }

  .v-input:is(:hover, :focus-within, .v-input--is-focused):not(.v-input--is-disabled) {
    --deck-number-input-border-color: var(--z-input-border-color-highlight);
  }
}

.deck-number-input__input--dense {
  --deck-number-input-font-size: var(--z-font-size-small);
}
</style>
