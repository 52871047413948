import apiClient from '~/assets/javascript/api';
import type { FetchInstance } from '@refactorjs/ofetch';

interface ApiPluginInjection {
  $api: FetchInstance
  $apiClient: ReturnType<typeof apiClient>
  $externalApi: FetchInstance
}

declare module '#app' {
  // @ts-expect-error NuxtApp recursive issue
  interface NuxtApp extends ApiPluginInjection { }
}

declare module 'vue' {
  interface ComponentCustomProperties extends ApiPluginInjection { }
}

export default defineNuxtPlugin(({ $http }) => {
  const externalApi = ($http as FetchInstance).create({});
  // Remove the Authorization header inherited from the $http instance
  externalApi.setHeader('Authorization', null);

  return {
    provide: {
      api: $http,
      apiClient: apiClient($http as FetchInstance),
      externalApi,
    },
  };
});
