
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexjMc5HxiY2OMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/index.vue?macro=true";
import { default as indexGYKYpn4Zb0Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexsNvFUY5oTuMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yetcgS1mZ6Lz3Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as index0gdeRqwYoFMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspaceIqgnhYBjFvMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as index48aYZQgtmzMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_93NV4iEfnO19Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexWw8rmltEHOMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexxu88gCdLKGMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as indexJy7RHxBzy9Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexGoXdisEsrPMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/install/-review/index.vue?macro=true";
import { default as _91app_93NTv5n2HtuPMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/install/[app].vue?macro=true";
import { default as index8KNYIajzvsMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexbF2VubvqalMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indexIfR540OB7PMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as newqGHCBBcyBMMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationDzxZIefAQ8Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexzpPRoO4CH1Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as mOAcU9kuhItMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexKdJUnHKkCrMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as indexlazZd3akiVMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexkYmbLGT4SnMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordDWk7uezwP6Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indext91uas4nZqMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexsr4FIIHnvIMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historytcWN5Uasa0Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexnHGvzr0TEQMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexz3DFZ18NGjMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importWuxZhIMOK5Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indexqBvoXRg2d6Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemaCGj0ApLyEZMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as indexO4fCgMH0CkMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as indextPSg76efIcMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_93qocDgUDm8JMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as indexlaWvL6RxL9Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as indexxugfszDm3JMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as indexjNTXhJZCxvMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexlBO369FgNhMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as index8tnmiGGtqNMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91providerSlug_93sC2Qx2As6UMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[providerSlug].vue?macro=true";
import { default as indexsNA9pJmPnYMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsIvOaCpBFITMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as index7Y7YaDDZYzMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as indexa0doRtvClnMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as index2sA3V9j1QpMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93cf899cwA1XMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexcQY7HSNGRLMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as new2CZbL4Q4afMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groups8DeWSSEJP5Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as user_45signup5P45EJcO5gMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-signup.vue?macro=true";
import { default as index3MXw3bS3LgMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settingsISpJZkmVp3Meta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studio5guvWgw2CPMeta } from "/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexjMc5HxiY2OMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexjMc5HxiY2OMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_93NV4iEfnO19Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_93NV4iEfnO19Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexWw8rmltEHOMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexWw8rmltEHOMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexxu88gCdLKGMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexxu88gCdLKGMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: indexJy7RHxBzy9Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: indexJy7RHxBzy9Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-install--review___en",
    path: "/en/t/:tenantSlug()/install/-review",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/install/-review/index.vue")
  },
  {
    name: "t-tenantSlug-install--review___pt",
    path: "/t/:tenantSlug()/install/-review",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/install/-review/index.vue")
  },
  {
    name: "t-tenantSlug-install-app___en",
    path: "/en/t/:tenantSlug()/install/:app()",
    meta: _91app_93NTv5n2HtuPMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/install/[app].vue")
  },
  {
    name: "t-tenantSlug-install-app___pt",
    path: "/t/:tenantSlug()/install/:app()",
    meta: _91app_93NTv5n2HtuPMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/install/[app].vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: index8KNYIajzvsMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: index8KNYIajzvsMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: mOAcU9kuhItMeta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: mOAcU9kuhItMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexbF2VubvqalMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indexIfR540OB7PMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: newqGHCBBcyBMMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationDzxZIefAQ8Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexzpPRoO4CH1Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: mOAcU9kuhItMeta?.name,
    path: "/t/:tenantSlug()/m",
    meta: mOAcU9kuhItMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexbF2VubvqalMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indexIfR540OB7PMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: newqGHCBBcyBMMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationDzxZIefAQ8Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexzpPRoO4CH1Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexKdJUnHKkCrMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexKdJUnHKkCrMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexlazZd3akiVMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: indexlazZd3akiVMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexkYmbLGT4SnMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexkYmbLGT4SnMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordDWk7uezwP6Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordDWk7uezwP6Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indext91uas4nZqMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indext91uas4nZqMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    meta: indexsr4FIIHnvIMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    meta: indexsr4FIIHnvIMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studio5guvWgw2CPMeta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studio5guvWgw2CPMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historytcWN5Uasa0Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexnHGvzr0TEQMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexz3DFZ18NGjMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importWuxZhIMOK5Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indexqBvoXRg2d6Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemaCGj0ApLyEZMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: indexO4fCgMH0CkMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_93qocDgUDm8JMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: indexlaWvL6RxL9Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsISpJZkmVp3Meta?.name,
    path: "settings",
    meta: settingsISpJZkmVp3Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsIvOaCpBFITMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-providerSlug___en",
    path: ":providerSlug()",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[providerSlug].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groups8DeWSSEJP5Meta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-user-signup___en",
    path: "user-signup",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-signup.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studio5guvWgw2CPMeta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studio5guvWgw2CPMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historytcWN5Uasa0Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexnHGvzr0TEQMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexz3DFZ18NGjMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importWuxZhIMOK5Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indexqBvoXRg2d6Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemaCGj0ApLyEZMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: indexO4fCgMH0CkMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_93qocDgUDm8JMeta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: indexlaWvL6RxL9Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settingsISpJZkmVp3Meta?.name,
    path: "settings",
    meta: settingsISpJZkmVp3Meta || {},
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsIvOaCpBFITMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-providerSlug___pt",
    path: ":providerSlug()",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[providerSlug].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groups8DeWSSEJP5Meta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-user-signup___pt",
    path: "user-signup",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-signup.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src3963973704/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]