import ROUTES from '~/assets/javascript/constants/routes';

export const UNSENT_VIEW_ATTRIBUTES = Object.freeze([
  'fields',
  'fields_ids',
  'fields_required',
  'fields_uneditable',
  'has_changes',
  'layoutOptions',
  'recordIds',
  'sheet',
  'subviews',
]);

export const SUBPAGE_AVAILABLE_ROUTES = Object.freeze({
  user: [ROUTES.member.accountConfiguration.routeKey],
  config: [
    ROUTES.studio.settings.routeKey,
    ROUTES.studio.settings.authentication.routeKey,
    ROUTES.studio.settings.globalParametersManager.routeKey,
    ROUTES.studio.settings.integrations.routeKey,
    ROUTES.studio.settings.integration.routeKey,
    ROUTES.studio.settings.tokens.routeKey,
    ROUTES.studio.settings.userGroups.routeKey,
    ROUTES.studio.settings.userSignup.routeKey,
    ROUTES.studio.settings.users.routeKey,

  ],
});
